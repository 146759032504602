import { useUser } from '../../contexts/user-context/UserContext';
import useApiStatus, { ApiStatus } from '../use-api-status/useApiStatus';
import { navigate } from 'gatsby';
import { set } from 'lodash';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

export type GitLabApiProps = {
  apiStatus: ApiStatus;
  query: string;
  payload?: any;
  rateLimit?: GitlabRateLimit;
  gitlabUrl?: string | null;
};

export type GitlabRateLimit = {
  limit?: number;
  remaining?: number;
  resetAt?: number;
  used?: number;
  limited: boolean;
};

class HttpResponseError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
    Object.setPrototypeOf(this, HttpResponseError.prototype);
  }
}

/**
 * A hook for using the Gitlab graphql api through judge-api
 * for testing raw graphql to gitlab, check out: https://gitlab.com/-/graphql-explorer
 *
 */
const useGitLab = (q: string, isEnabled: boolean): [GitLabApiProps, (q: string) => void] => {
  const user = useUser();
  const [apiStatus, setIsLoading, setHasError, setError] = useApiStatus();
  const [results, setApiResults] = useState({} as any);
  const [rateLimit, setRateLimit] = useState<GitlabRateLimit>();
  const [gitlabUrl, setGitlabUrl] = useState<string | null>();

  const [query, setQuery] = useState(q);
  const judgeApiProps: GitLabApiProps = { apiStatus, query, rateLimit };

  const getQuery = useCallback(async () => {
    if (!isEnabled) {
      return;
    }
    try {
      setIsLoading(true);
      setHasError(false);

      const response = await fetch(`/judge-api/gitlab?query=${query}`, {
        method: 'GET',
        credentials: 'include',
      });

      const json = await response.json();
      setGitlabUrl(response.headers.get('x-gitlab-url'));
      setApiResults(json?.data);
      if (json?.errors) {
        setError({ jsonErrors: json.errors, status: response.status });
        setHasError(true);
      }
    } catch (error) {
      setError(error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [isEnabled, query, setError, setHasError, setIsLoading]);

  useEffect(() => {
    if (apiStatus.error) {
      console.error(apiStatus.error);

      if (apiStatus.error instanceof HttpResponseError) {
        const response = apiStatus.error.response;

        setRateLimit({
          limit: response.headers.get('ratelimit-limit') ? parseInt(response.headers.get('ratelimit-limit') as string) : 0,
          remaining: response.headers.get('ratelimit-remaining') ? parseInt(response.headers.get('ratelimit-remaining') as string) : 0,
          resetAt: response.headers.get('ratelimit-reset') ? parseInt(response.headers.get('ratelimit-reset') as string) : 0,
          used: response.headers.get('ratelimit-used') ? parseInt(response.headers.get('ratelimit-used') as string) : 0,
          limited: true,
        });
      }
    }
  }, [apiStatus.error]);

  useLayoutEffect(() => {
    if (query?.length >= 3) {
      void getQuery();
    }
  }, [getQuery, query?.length]);

  useEffect(() => {
    if (apiStatus.hasError && user.logoutUrl) {
      if (apiStatus.error?.status === 401) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(user.logoutUrl);
      }
    }
  }, [apiStatus.error, apiStatus.hasError, user.logoutUrl]);

  return [{ apiStatus: judgeApiProps.apiStatus, payload: results, query: query, gitlabUrl }, setQuery];
};

export default useGitLab;
