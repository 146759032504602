import { ConfigConstants } from './shared/constants';
import { SearchProvider } from './shared/contexts/search-context/SearchContext';
import { ThemeProvider } from './shared/contexts/theme-context/ThemeContext';
import { UiStateProvider } from './shared/contexts/ui-state-context/UiStateContext';
import { UserProvider } from './shared/contexts/user-context/UserContext';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { LocationProvider } from '@gatsbyjs/reach-router';
import React, { PropsWithChildren, useEffect } from 'react';

/**
 * This is the HighOrder app component fot bootstrapping JS things into our app globally, above the layout and pages.
 * There is one more level above this in gatsby-browser.ts for static things that we bootstrap globally.
 *
 * @param {*} { children }
 * @returns
 */
const App: React.FC<PropsWithChildren> = ({ children }) => {
  const [graphQLClient, setGraphQLClient] = React.useState<ApolloClient<unknown>>();
  useEffect(() => {
    const client = new ApolloClient({
      cache: new InMemoryCache(),
      uri: `${ConfigConstants?.gateway_svc || ''}/query`,
    });
    setGraphQLClient(client);
  }, []);

  return (
    <>
      <ThemeProvider>
        <LocationProvider>
          <UiStateProvider>
            {graphQLClient ? (
              <ApolloProvider client={graphQLClient}>
                <UserProvider client={graphQLClient}>
                  <SearchProvider>{children}</SearchProvider>
                </UserProvider>
              </ApolloProvider>
            ) : (
              children
            )}
          </UiStateProvider>
        </LocationProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
