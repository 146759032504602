import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Cursor: any;
  Map: any;
  Time: any;
};

export type Attestation = Node & {
  __typename?: 'Attestation';
  attestationCollection: AttestationCollection;
  endtime: Scalars['Time'];
  id: Scalars['ID'];
  starttime: Scalars['Time'];
  type: Scalars['String'];
};

export type AttestationCollection = Node & {
  __typename?: 'AttestationCollection';
  attestations?: Maybe<Array<Attestation>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  statement: Statement;
};

/**
 * AttestationCollectionWhereInput is used for filtering AttestationCollection objects.
 * Input was generated by ent.
 */
export type AttestationCollectionWhereInput = {
  and?: InputMaybe<Array<AttestationCollectionWhereInput>>;
  /** attestations edge predicates */
  hasAttestations?: InputMaybe<Scalars['Boolean']>;
  hasAttestationsWith?: InputMaybe<Array<AttestationWhereInput>>;
  /** statement edge predicates */
  hasStatement?: InputMaybe<Scalars['Boolean']>;
  hasStatementWith?: InputMaybe<Array<StatementWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<AttestationCollectionWhereInput>;
  or?: InputMaybe<Array<AttestationCollectionWhereInput>>;
};

/** A connection to a list of items. */
export type AttestationConnection = {
  __typename?: 'AttestationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AttestationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AttestationEdge = {
  __typename?: 'AttestationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<Attestation>;
};

/** Ordering options for Attestation connections */
export type AttestationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Attestations. */
  field: AttestationOrderField;
};

/** Properties by which Attestation connections can be ordered. */
export enum AttestationOrderField {
  Endtime = 'ENDTIME',
  Starttime = 'STARTTIME',
}

export type AttestationPolicy = Node & {
  __typename?: 'AttestationPolicy';
  id: Scalars['ID'];
  name: Scalars['String'];
  statement?: Maybe<Statement>;
  uploadedAt: Scalars['Time'];
};

/** A connection to a list of items. */
export type AttestationPolicyConnection = {
  __typename?: 'AttestationPolicyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AttestationPolicyEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AttestationPolicyEdge = {
  __typename?: 'AttestationPolicyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<AttestationPolicy>;
};

/** Ordering options for AttestationPolicy connections */
export type AttestationPolicyOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AttestationPolicies. */
  field: AttestationPolicyOrderField;
};

/** Properties by which AttestationPolicy connections can be ordered. */
export enum AttestationPolicyOrderField {
  UploadedAt = 'UPLOADED_AT',
}

/**
 * AttestationPolicyWhereInput is used for filtering AttestationPolicy objects.
 * Input was generated by ent.
 */
export type AttestationPolicyWhereInput = {
  and?: InputMaybe<Array<AttestationPolicyWhereInput>>;
  /** statement edge predicates */
  hasStatement?: InputMaybe<Scalars['Boolean']>;
  hasStatementWith?: InputMaybe<Array<StatementWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<AttestationPolicyWhereInput>;
  or?: InputMaybe<Array<AttestationPolicyWhereInput>>;
  /** uploaded_at field predicates */
  uploadedAt?: InputMaybe<Scalars['Time']>;
  uploadedAtGT?: InputMaybe<Scalars['Time']>;
  uploadedAtGTE?: InputMaybe<Scalars['Time']>;
  uploadedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  uploadedAtLT?: InputMaybe<Scalars['Time']>;
  uploadedAtLTE?: InputMaybe<Scalars['Time']>;
  uploadedAtNEQ?: InputMaybe<Scalars['Time']>;
  uploadedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

/**
 * AttestationWhereInput is used for filtering Attestation objects.
 * Input was generated by ent.
 */
export type AttestationWhereInput = {
  and?: InputMaybe<Array<AttestationWhereInput>>;
  /** endtime field predicates */
  endtime?: InputMaybe<Scalars['Time']>;
  endtimeGT?: InputMaybe<Scalars['Time']>;
  endtimeGTE?: InputMaybe<Scalars['Time']>;
  endtimeIn?: InputMaybe<Array<Scalars['Time']>>;
  endtimeLT?: InputMaybe<Scalars['Time']>;
  endtimeLTE?: InputMaybe<Scalars['Time']>;
  endtimeNEQ?: InputMaybe<Scalars['Time']>;
  endtimeNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** attestation_collection edge predicates */
  hasAttestationCollection?: InputMaybe<Scalars['Boolean']>;
  hasAttestationCollectionWith?: InputMaybe<Array<AttestationCollectionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  not?: InputMaybe<AttestationWhereInput>;
  or?: InputMaybe<Array<AttestationWhereInput>>;
  /** starttime field predicates */
  starttime?: InputMaybe<Scalars['Time']>;
  starttimeGT?: InputMaybe<Scalars['Time']>;
  starttimeGTE?: InputMaybe<Scalars['Time']>;
  starttimeIn?: InputMaybe<Array<Scalars['Time']>>;
  starttimeLT?: InputMaybe<Scalars['Time']>;
  starttimeLTE?: InputMaybe<Scalars['Time']>;
  starttimeNEQ?: InputMaybe<Scalars['Time']>;
  starttimeNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']>;
  typeContains?: InputMaybe<Scalars['String']>;
  typeContainsFold?: InputMaybe<Scalars['String']>;
  typeEqualFold?: InputMaybe<Scalars['String']>;
  typeGT?: InputMaybe<Scalars['String']>;
  typeGTE?: InputMaybe<Scalars['String']>;
  typeHasPrefix?: InputMaybe<Scalars['String']>;
  typeHasSuffix?: InputMaybe<Scalars['String']>;
  typeIn?: InputMaybe<Array<Scalars['String']>>;
  typeLT?: InputMaybe<Scalars['String']>;
  typeLTE?: InputMaybe<Scalars['String']>;
  typeNEQ?: InputMaybe<Scalars['String']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']>>;
};

export type Branch = Node & {
  __typename?: 'Branch';
  commit?: Maybe<CodeCommit>;
  commitID?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  gitProviderRepoID: Scalars['String'];
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  name: Scalars['String'];
  repo?: Maybe<Repo>;
  tenant: Tenant;
  updatedAt: Scalars['Time'];
};

/** A connection to a list of items. */
export type BranchConnection = {
  __typename?: 'BranchConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BranchEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BranchEdge = {
  __typename?: 'BranchEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<Branch>;
};

/** Ordering options for Branch connections */
export type BranchOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Branches. */
  field: BranchOrderField;
};

/** Properties by which Branch connections can be ordered. */
export enum BranchOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
  Name = 'name',
}

/**
 * BranchWhereInput is used for filtering Branch objects.
 * Input was generated by ent.
 */
export type BranchWhereInput = {
  and?: InputMaybe<Array<BranchWhereInput>>;
  /** commit_id field predicates */
  commitID?: InputMaybe<Scalars['ID']>;
  commitIDIn?: InputMaybe<Array<Scalars['ID']>>;
  commitIDIsNil?: InputMaybe<Scalars['Boolean']>;
  commitIDNEQ?: InputMaybe<Scalars['ID']>;
  commitIDNotIn?: InputMaybe<Array<Scalars['ID']>>;
  commitIDNotNil?: InputMaybe<Scalars['Boolean']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** git_provider_repo_id field predicates */
  gitProviderRepoID?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDContains?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDContainsFold?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDEqualFold?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDGT?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDGTE?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDHasPrefix?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDHasSuffix?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDIn?: InputMaybe<Array<Scalars['String']>>;
  gitProviderRepoIDLT?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDLTE?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDNEQ?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** commit edge predicates */
  hasCommit?: InputMaybe<Scalars['Boolean']>;
  hasCommitWith?: InputMaybe<Array<CodeCommitWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** repo edge predicates */
  hasRepo?: InputMaybe<Scalars['Boolean']>;
  hasRepoWith?: InputMaybe<Array<RepoWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<BranchWhereInput>;
  or?: InputMaybe<Array<BranchWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type CaProvider = Node & {
  __typename?: 'CAProvider';
  /** CA URL */
  caURL?: Maybe<Scalars['String']>;
  /** Cache expiry time for the provider data */
  cacheExp?: Maybe<Scalars['Time']>;
  certConstraints?: Maybe<Array<CertConstraint>>;
  chains?: Maybe<Array<Chain>>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  functionaryStore?: Maybe<Array<FunctionaryStore>>;
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  /** Name of the CA provider */
  name: Scalars['String'];
  tenant: Tenant;
  updatedAt: Scalars['Time'];
};

/** Ordering options for CAProvider connections */
export type CaProviderOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CAProviders. */
  field: CaProviderOrderField;
};

/** Properties by which CAProvider connections can be ordered. */
export enum CaProviderOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * CAProviderWhereInput is used for filtering CAProvider objects.
 * Input was generated by ent.
 */
export type CaProviderWhereInput = {
  and?: InputMaybe<Array<CaProviderWhereInput>>;
  /** ca_url field predicates */
  caURL?: InputMaybe<Scalars['String']>;
  caURLContains?: InputMaybe<Scalars['String']>;
  caURLContainsFold?: InputMaybe<Scalars['String']>;
  caURLEqualFold?: InputMaybe<Scalars['String']>;
  caURLGT?: InputMaybe<Scalars['String']>;
  caURLGTE?: InputMaybe<Scalars['String']>;
  caURLHasPrefix?: InputMaybe<Scalars['String']>;
  caURLHasSuffix?: InputMaybe<Scalars['String']>;
  caURLIn?: InputMaybe<Array<Scalars['String']>>;
  caURLIsNil?: InputMaybe<Scalars['Boolean']>;
  caURLLT?: InputMaybe<Scalars['String']>;
  caURLLTE?: InputMaybe<Scalars['String']>;
  caURLNEQ?: InputMaybe<Scalars['String']>;
  caURLNotIn?: InputMaybe<Array<Scalars['String']>>;
  caURLNotNil?: InputMaybe<Scalars['Boolean']>;
  /** cache_exp field predicates */
  cacheExp?: InputMaybe<Scalars['Time']>;
  cacheExpGT?: InputMaybe<Scalars['Time']>;
  cacheExpGTE?: InputMaybe<Scalars['Time']>;
  cacheExpIn?: InputMaybe<Array<Scalars['Time']>>;
  cacheExpIsNil?: InputMaybe<Scalars['Boolean']>;
  cacheExpLT?: InputMaybe<Scalars['Time']>;
  cacheExpLTE?: InputMaybe<Scalars['Time']>;
  cacheExpNEQ?: InputMaybe<Scalars['Time']>;
  cacheExpNotIn?: InputMaybe<Array<Scalars['Time']>>;
  cacheExpNotNil?: InputMaybe<Scalars['Boolean']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** cert_constraints edge predicates */
  hasCertConstraints?: InputMaybe<Scalars['Boolean']>;
  hasCertConstraintsWith?: InputMaybe<Array<CertConstraintWhereInput>>;
  /** chains edge predicates */
  hasChains?: InputMaybe<Scalars['Boolean']>;
  hasChainsWith?: InputMaybe<Array<ChainWhereInput>>;
  /** functionary_store edge predicates */
  hasFunctionaryStore?: InputMaybe<Scalars['Boolean']>;
  hasFunctionaryStoreWith?: InputMaybe<Array<FunctionaryStoreWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<CaProviderWhereInput>;
  or?: InputMaybe<Array<CaProviderWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type CertConstraint = Node & {
  __typename?: 'CertConstraint';
  caProvider?: Maybe<CaProvider>;
  /** Common name that the certificate's subject must have */
  commonName?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  /** Additional details or context for the constraint */
  details?: Maybe<Scalars['String']>;
  /** DNS names that the certificate must have */
  dnsNames?: Maybe<Array<Scalars['String']>>;
  /** Email addresses that the certificate must have */
  emails?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  /** OID constraints in the form of key-value pairs */
  oidConstraints?: Maybe<Scalars['Map']>;
  /** Organizations that the certificate must have */
  organizations?: Maybe<Array<Scalars['String']>>;
  /** Array of Key IDs the signer's certificate must belong to to be trusted */
  roots?: Maybe<Array<Scalars['String']>>;
  tenant: Tenant;
  updatedAt: Scalars['Time'];
  /** URIs that the certificate must have */
  uris?: Maybe<Array<Scalars['String']>>;
};

/** Ordering options for CertConstraint connections */
export type CertConstraintOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CertConstraints. */
  field: CertConstraintOrderField;
};

/** Properties by which CertConstraint connections can be ordered. */
export enum CertConstraintOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * CertConstraintWhereInput is used for filtering CertConstraint objects.
 * Input was generated by ent.
 */
export type CertConstraintWhereInput = {
  and?: InputMaybe<Array<CertConstraintWhereInput>>;
  /** common_name field predicates */
  commonName?: InputMaybe<Scalars['String']>;
  commonNameContains?: InputMaybe<Scalars['String']>;
  commonNameContainsFold?: InputMaybe<Scalars['String']>;
  commonNameEqualFold?: InputMaybe<Scalars['String']>;
  commonNameGT?: InputMaybe<Scalars['String']>;
  commonNameGTE?: InputMaybe<Scalars['String']>;
  commonNameHasPrefix?: InputMaybe<Scalars['String']>;
  commonNameHasSuffix?: InputMaybe<Scalars['String']>;
  commonNameIn?: InputMaybe<Array<Scalars['String']>>;
  commonNameIsNil?: InputMaybe<Scalars['Boolean']>;
  commonNameLT?: InputMaybe<Scalars['String']>;
  commonNameLTE?: InputMaybe<Scalars['String']>;
  commonNameNEQ?: InputMaybe<Scalars['String']>;
  commonNameNotIn?: InputMaybe<Array<Scalars['String']>>;
  commonNameNotNil?: InputMaybe<Scalars['Boolean']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** details field predicates */
  details?: InputMaybe<Scalars['String']>;
  detailsContains?: InputMaybe<Scalars['String']>;
  detailsContainsFold?: InputMaybe<Scalars['String']>;
  detailsEqualFold?: InputMaybe<Scalars['String']>;
  detailsGT?: InputMaybe<Scalars['String']>;
  detailsGTE?: InputMaybe<Scalars['String']>;
  detailsHasPrefix?: InputMaybe<Scalars['String']>;
  detailsHasSuffix?: InputMaybe<Scalars['String']>;
  detailsIn?: InputMaybe<Array<Scalars['String']>>;
  detailsIsNil?: InputMaybe<Scalars['Boolean']>;
  detailsLT?: InputMaybe<Scalars['String']>;
  detailsLTE?: InputMaybe<Scalars['String']>;
  detailsNEQ?: InputMaybe<Scalars['String']>;
  detailsNotIn?: InputMaybe<Array<Scalars['String']>>;
  detailsNotNil?: InputMaybe<Scalars['Boolean']>;
  /** ca_provider edge predicates */
  hasCaProvider?: InputMaybe<Scalars['Boolean']>;
  hasCaProviderWith?: InputMaybe<Array<CaProviderWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  not?: InputMaybe<CertConstraintWhereInput>;
  or?: InputMaybe<Array<CertConstraintWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type Certificate = Node & {
  __typename?: 'Certificate';
  /** Authority key identifier */
  aki?: Maybe<Scalars['String']>;
  chain?: Maybe<Chain>;
  /** Certificate policies extension */
  cp?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  /** DNS names in the certificate's SAN extension */
  dns?: Maybe<Array<Scalars['String']>>;
  /** Extended key usage extension */
  eku?: Maybe<Array<Scalars['String']>>;
  /** Email addresses in the certificate's SAN extension */
  emails?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  /** IP addresses in the certificate's SAN extension */
  ips?: Maybe<Array<Scalars['String']>>;
  /** Common name of the issuer */
  issCn?: Maybe<Scalars['String']>;
  /** Country of the issuer */
  issCtry?: Maybe<Array<Scalars['String']>>;
  /** Locality of the issuer */
  issLoc?: Maybe<Array<Scalars['String']>>;
  /** Organization of the issuer */
  issOrg?: Maybe<Array<Scalars['String']>>;
  /** Organizational Unit of the issuer */
  issOu?: Maybe<Array<Scalars['String']>>;
  /** State or Province of the issuer */
  issSt?: Maybe<Array<Scalars['String']>>;
  issuedCerts?: Maybe<Array<Certificate>>;
  issuer?: Maybe<Certificate>;
  /** The Key ID associated with this certificate */
  kid?: Maybe<Scalars['String']>;
  /** Key usage extension */
  ku?: Maybe<Array<Scalars['String']>>;
  modifiedBy?: Maybe<User>;
  /** The end of the certificate's validity period */
  notAfter?: Maybe<Scalars['Time']>;
  /** The start of the certificate's validity period */
  notBefore?: Maybe<Scalars['Time']>;
  /** Base64 encoded PEM block describing a valid certificate (either CA, intermediate, leaf, or TSA) */
  pem: Scalars['String'];
  /** Serial number of the certificate */
  serialNumber?: Maybe<Scalars['String']>;
  /** SHA-256 fingerprint of the certificate */
  sha256Fingerprint?: Maybe<Scalars['String']>;
  /** Sigstore OIDs constraints */
  sigOids?: Maybe<Array<Scalars['String']>>;
  /** Subject key identifier */
  ski?: Maybe<Scalars['String']>;
  /** Common name that the certificate's subject must have */
  subjCn?: Maybe<Scalars['String']>;
  /** Country that the certificate's subject must have */
  subjCtry?: Maybe<Array<Scalars['String']>>;
  /** Locality that the certificate's subject must have */
  subjLoc?: Maybe<Array<Scalars['String']>>;
  /** Organization that the certificate's subject must have */
  subjOrg?: Maybe<Array<Scalars['String']>>;
  /** Organizational Unit that the certificate's subject must have */
  subjOu?: Maybe<Array<Scalars['String']>>;
  /** State or Province that the certificate's subject must have */
  subjSt?: Maybe<Array<Scalars['String']>>;
  tenant: Tenant;
  /** Type of the certificate: 'ca', 'intermediate', 'leaf', 'tsa' */
  type: CertificateType;
  updatedAt: Scalars['Time'];
  /** URIs in the certificate's SAN extension */
  uris?: Maybe<Array<Scalars['String']>>;
};

/** Ordering options for Certificate connections */
export type CertificateOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Certificates. */
  field: CertificateOrderField;
};

/** Properties by which Certificate connections can be ordered. */
export enum CertificateOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/** CertificateType is enum for the field type */
export enum CertificateType {
  Ca = 'ca',
  Intermediate = 'intermediate',
  Leaf = 'leaf',
}

/**
 * CertificateWhereInput is used for filtering Certificate objects.
 * Input was generated by ent.
 */
export type CertificateWhereInput = {
  /** aki field predicates */
  aki?: InputMaybe<Scalars['String']>;
  akiContains?: InputMaybe<Scalars['String']>;
  akiContainsFold?: InputMaybe<Scalars['String']>;
  akiEqualFold?: InputMaybe<Scalars['String']>;
  akiGT?: InputMaybe<Scalars['String']>;
  akiGTE?: InputMaybe<Scalars['String']>;
  akiHasPrefix?: InputMaybe<Scalars['String']>;
  akiHasSuffix?: InputMaybe<Scalars['String']>;
  akiIn?: InputMaybe<Array<Scalars['String']>>;
  akiIsNil?: InputMaybe<Scalars['Boolean']>;
  akiLT?: InputMaybe<Scalars['String']>;
  akiLTE?: InputMaybe<Scalars['String']>;
  akiNEQ?: InputMaybe<Scalars['String']>;
  akiNotIn?: InputMaybe<Array<Scalars['String']>>;
  akiNotNil?: InputMaybe<Scalars['Boolean']>;
  and?: InputMaybe<Array<CertificateWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** chain edge predicates */
  hasChain?: InputMaybe<Scalars['Boolean']>;
  hasChainWith?: InputMaybe<Array<ChainWhereInput>>;
  /** issued_certs edge predicates */
  hasIssuedCerts?: InputMaybe<Scalars['Boolean']>;
  hasIssuedCertsWith?: InputMaybe<Array<CertificateWhereInput>>;
  /** issuer edge predicates */
  hasIssuer?: InputMaybe<Scalars['Boolean']>;
  hasIssuerWith?: InputMaybe<Array<CertificateWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** iss_cn field predicates */
  issCn?: InputMaybe<Scalars['String']>;
  issCnContains?: InputMaybe<Scalars['String']>;
  issCnContainsFold?: InputMaybe<Scalars['String']>;
  issCnEqualFold?: InputMaybe<Scalars['String']>;
  issCnGT?: InputMaybe<Scalars['String']>;
  issCnGTE?: InputMaybe<Scalars['String']>;
  issCnHasPrefix?: InputMaybe<Scalars['String']>;
  issCnHasSuffix?: InputMaybe<Scalars['String']>;
  issCnIn?: InputMaybe<Array<Scalars['String']>>;
  issCnIsNil?: InputMaybe<Scalars['Boolean']>;
  issCnLT?: InputMaybe<Scalars['String']>;
  issCnLTE?: InputMaybe<Scalars['String']>;
  issCnNEQ?: InputMaybe<Scalars['String']>;
  issCnNotIn?: InputMaybe<Array<Scalars['String']>>;
  issCnNotNil?: InputMaybe<Scalars['Boolean']>;
  /** kid field predicates */
  kid?: InputMaybe<Scalars['String']>;
  kidContains?: InputMaybe<Scalars['String']>;
  kidContainsFold?: InputMaybe<Scalars['String']>;
  kidEqualFold?: InputMaybe<Scalars['String']>;
  kidGT?: InputMaybe<Scalars['String']>;
  kidGTE?: InputMaybe<Scalars['String']>;
  kidHasPrefix?: InputMaybe<Scalars['String']>;
  kidHasSuffix?: InputMaybe<Scalars['String']>;
  kidIn?: InputMaybe<Array<Scalars['String']>>;
  kidIsNil?: InputMaybe<Scalars['Boolean']>;
  kidLT?: InputMaybe<Scalars['String']>;
  kidLTE?: InputMaybe<Scalars['String']>;
  kidNEQ?: InputMaybe<Scalars['String']>;
  kidNotIn?: InputMaybe<Array<Scalars['String']>>;
  kidNotNil?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<CertificateWhereInput>;
  /** not_after field predicates */
  notAfter?: InputMaybe<Scalars['Time']>;
  notAfterGT?: InputMaybe<Scalars['Time']>;
  notAfterGTE?: InputMaybe<Scalars['Time']>;
  notAfterIn?: InputMaybe<Array<Scalars['Time']>>;
  notAfterIsNil?: InputMaybe<Scalars['Boolean']>;
  notAfterLT?: InputMaybe<Scalars['Time']>;
  notAfterLTE?: InputMaybe<Scalars['Time']>;
  notAfterNEQ?: InputMaybe<Scalars['Time']>;
  notAfterNotIn?: InputMaybe<Array<Scalars['Time']>>;
  notAfterNotNil?: InputMaybe<Scalars['Boolean']>;
  /** not_before field predicates */
  notBefore?: InputMaybe<Scalars['Time']>;
  notBeforeGT?: InputMaybe<Scalars['Time']>;
  notBeforeGTE?: InputMaybe<Scalars['Time']>;
  notBeforeIn?: InputMaybe<Array<Scalars['Time']>>;
  notBeforeIsNil?: InputMaybe<Scalars['Boolean']>;
  notBeforeLT?: InputMaybe<Scalars['Time']>;
  notBeforeLTE?: InputMaybe<Scalars['Time']>;
  notBeforeNEQ?: InputMaybe<Scalars['Time']>;
  notBeforeNotIn?: InputMaybe<Array<Scalars['Time']>>;
  notBeforeNotNil?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<CertificateWhereInput>>;
  /** pem field predicates */
  pem?: InputMaybe<Scalars['String']>;
  pemContains?: InputMaybe<Scalars['String']>;
  pemContainsFold?: InputMaybe<Scalars['String']>;
  pemEqualFold?: InputMaybe<Scalars['String']>;
  pemGT?: InputMaybe<Scalars['String']>;
  pemGTE?: InputMaybe<Scalars['String']>;
  pemHasPrefix?: InputMaybe<Scalars['String']>;
  pemHasSuffix?: InputMaybe<Scalars['String']>;
  pemIn?: InputMaybe<Array<Scalars['String']>>;
  pemLT?: InputMaybe<Scalars['String']>;
  pemLTE?: InputMaybe<Scalars['String']>;
  pemNEQ?: InputMaybe<Scalars['String']>;
  pemNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** serial_number field predicates */
  serialNumber?: InputMaybe<Scalars['String']>;
  serialNumberContains?: InputMaybe<Scalars['String']>;
  serialNumberContainsFold?: InputMaybe<Scalars['String']>;
  serialNumberEqualFold?: InputMaybe<Scalars['String']>;
  serialNumberGT?: InputMaybe<Scalars['String']>;
  serialNumberGTE?: InputMaybe<Scalars['String']>;
  serialNumberHasPrefix?: InputMaybe<Scalars['String']>;
  serialNumberHasSuffix?: InputMaybe<Scalars['String']>;
  serialNumberIn?: InputMaybe<Array<Scalars['String']>>;
  serialNumberIsNil?: InputMaybe<Scalars['Boolean']>;
  serialNumberLT?: InputMaybe<Scalars['String']>;
  serialNumberLTE?: InputMaybe<Scalars['String']>;
  serialNumberNEQ?: InputMaybe<Scalars['String']>;
  serialNumberNotIn?: InputMaybe<Array<Scalars['String']>>;
  serialNumberNotNil?: InputMaybe<Scalars['Boolean']>;
  /** sha256_fingerprint field predicates */
  sha256Fingerprint?: InputMaybe<Scalars['String']>;
  sha256FingerprintContains?: InputMaybe<Scalars['String']>;
  sha256FingerprintContainsFold?: InputMaybe<Scalars['String']>;
  sha256FingerprintEqualFold?: InputMaybe<Scalars['String']>;
  sha256FingerprintGT?: InputMaybe<Scalars['String']>;
  sha256FingerprintGTE?: InputMaybe<Scalars['String']>;
  sha256FingerprintHasPrefix?: InputMaybe<Scalars['String']>;
  sha256FingerprintHasSuffix?: InputMaybe<Scalars['String']>;
  sha256FingerprintIn?: InputMaybe<Array<Scalars['String']>>;
  sha256FingerprintIsNil?: InputMaybe<Scalars['Boolean']>;
  sha256FingerprintLT?: InputMaybe<Scalars['String']>;
  sha256FingerprintLTE?: InputMaybe<Scalars['String']>;
  sha256FingerprintNEQ?: InputMaybe<Scalars['String']>;
  sha256FingerprintNotIn?: InputMaybe<Array<Scalars['String']>>;
  sha256FingerprintNotNil?: InputMaybe<Scalars['Boolean']>;
  /** ski field predicates */
  ski?: InputMaybe<Scalars['String']>;
  skiContains?: InputMaybe<Scalars['String']>;
  skiContainsFold?: InputMaybe<Scalars['String']>;
  skiEqualFold?: InputMaybe<Scalars['String']>;
  skiGT?: InputMaybe<Scalars['String']>;
  skiGTE?: InputMaybe<Scalars['String']>;
  skiHasPrefix?: InputMaybe<Scalars['String']>;
  skiHasSuffix?: InputMaybe<Scalars['String']>;
  skiIn?: InputMaybe<Array<Scalars['String']>>;
  skiIsNil?: InputMaybe<Scalars['Boolean']>;
  skiLT?: InputMaybe<Scalars['String']>;
  skiLTE?: InputMaybe<Scalars['String']>;
  skiNEQ?: InputMaybe<Scalars['String']>;
  skiNotIn?: InputMaybe<Array<Scalars['String']>>;
  skiNotNil?: InputMaybe<Scalars['Boolean']>;
  /** subj_cn field predicates */
  subjCn?: InputMaybe<Scalars['String']>;
  subjCnContains?: InputMaybe<Scalars['String']>;
  subjCnContainsFold?: InputMaybe<Scalars['String']>;
  subjCnEqualFold?: InputMaybe<Scalars['String']>;
  subjCnGT?: InputMaybe<Scalars['String']>;
  subjCnGTE?: InputMaybe<Scalars['String']>;
  subjCnHasPrefix?: InputMaybe<Scalars['String']>;
  subjCnHasSuffix?: InputMaybe<Scalars['String']>;
  subjCnIn?: InputMaybe<Array<Scalars['String']>>;
  subjCnIsNil?: InputMaybe<Scalars['Boolean']>;
  subjCnLT?: InputMaybe<Scalars['String']>;
  subjCnLTE?: InputMaybe<Scalars['String']>;
  subjCnNEQ?: InputMaybe<Scalars['String']>;
  subjCnNotIn?: InputMaybe<Array<Scalars['String']>>;
  subjCnNotNil?: InputMaybe<Scalars['Boolean']>;
  /** type field predicates */
  type?: InputMaybe<CertificateType>;
  typeIn?: InputMaybe<Array<CertificateType>>;
  typeNEQ?: InputMaybe<CertificateType>;
  typeNotIn?: InputMaybe<Array<CertificateType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type Chain = Node & {
  __typename?: 'Chain';
  caProviders?: Maybe<CaProvider>;
  certificates?: Maybe<Array<Certificate>>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  /** Name of the certificate chain */
  name: Scalars['String'];
  tenant: Tenant;
  updatedAt: Scalars['Time'];
};

/** Ordering options for Chain connections */
export type ChainOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Chains. */
  field: ChainOrderField;
};

/** Properties by which Chain connections can be ordered. */
export enum ChainOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * ChainWhereInput is used for filtering Chain objects.
 * Input was generated by ent.
 */
export type ChainWhereInput = {
  and?: InputMaybe<Array<ChainWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** ca_providers edge predicates */
  hasCaProviders?: InputMaybe<Scalars['Boolean']>;
  hasCaProvidersWith?: InputMaybe<Array<CaProviderWhereInput>>;
  /** certificates edge predicates */
  hasCertificates?: InputMaybe<Scalars['Boolean']>;
  hasCertificatesWith?: InputMaybe<Array<CertificateWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<ChainWhereInput>;
  or?: InputMaybe<Array<ChainWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type CodeCommit = Node & {
  __typename?: 'CodeCommit';
  authors: Array<Scalars['String']>;
  committedDate: Scalars['String'];
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  gitProviderRepoID: Scalars['String'];
  id: Scalars['ID'];
  message: Scalars['String'];
  modifiedBy?: Maybe<User>;
  parentShas: Array<Scalars['String']>;
  path: Scalars['String'];
  repo?: Maybe<Array<Repo>>;
  sha: Scalars['String'];
  tenant: Tenant;
  updatedAt: Scalars['Time'];
  url: Scalars['String'];
};

/** A connection to a list of items. */
export type CodeCommitConnection = {
  __typename?: 'CodeCommitConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CodeCommitEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CodeCommitEdge = {
  __typename?: 'CodeCommitEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<CodeCommit>;
};

/** Ordering options for CodeCommit connections */
export type CodeCommitOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CodeCommits. */
  field: CodeCommitOrderField;
};

/** Properties by which CodeCommit connections can be ordered. */
export enum CodeCommitOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
  CommittedDate = 'committed_date',
  Path = 'path',
}

/**
 * CodeCommitWhereInput is used for filtering CodeCommit objects.
 * Input was generated by ent.
 */
export type CodeCommitWhereInput = {
  and?: InputMaybe<Array<CodeCommitWhereInput>>;
  /** committed_date field predicates */
  committedDate?: InputMaybe<Scalars['String']>;
  committedDateContains?: InputMaybe<Scalars['String']>;
  committedDateContainsFold?: InputMaybe<Scalars['String']>;
  committedDateEqualFold?: InputMaybe<Scalars['String']>;
  committedDateGT?: InputMaybe<Scalars['String']>;
  committedDateGTE?: InputMaybe<Scalars['String']>;
  committedDateHasPrefix?: InputMaybe<Scalars['String']>;
  committedDateHasSuffix?: InputMaybe<Scalars['String']>;
  committedDateIn?: InputMaybe<Array<Scalars['String']>>;
  committedDateLT?: InputMaybe<Scalars['String']>;
  committedDateLTE?: InputMaybe<Scalars['String']>;
  committedDateNEQ?: InputMaybe<Scalars['String']>;
  committedDateNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** git_provider_repo_id field predicates */
  gitProviderRepoID?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDContains?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDContainsFold?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDEqualFold?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDGT?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDGTE?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDHasPrefix?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDHasSuffix?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDIn?: InputMaybe<Array<Scalars['String']>>;
  gitProviderRepoIDLT?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDLTE?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDNEQ?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** repo edge predicates */
  hasRepo?: InputMaybe<Scalars['Boolean']>;
  hasRepoWith?: InputMaybe<Array<RepoWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** message field predicates */
  message?: InputMaybe<Scalars['String']>;
  messageContains?: InputMaybe<Scalars['String']>;
  messageContainsFold?: InputMaybe<Scalars['String']>;
  messageEqualFold?: InputMaybe<Scalars['String']>;
  messageGT?: InputMaybe<Scalars['String']>;
  messageGTE?: InputMaybe<Scalars['String']>;
  messageHasPrefix?: InputMaybe<Scalars['String']>;
  messageHasSuffix?: InputMaybe<Scalars['String']>;
  messageIn?: InputMaybe<Array<Scalars['String']>>;
  messageLT?: InputMaybe<Scalars['String']>;
  messageLTE?: InputMaybe<Scalars['String']>;
  messageNEQ?: InputMaybe<Scalars['String']>;
  messageNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<CodeCommitWhereInput>;
  or?: InputMaybe<Array<CodeCommitWhereInput>>;
  /** path field predicates */
  path?: InputMaybe<Scalars['String']>;
  pathContains?: InputMaybe<Scalars['String']>;
  pathContainsFold?: InputMaybe<Scalars['String']>;
  pathEqualFold?: InputMaybe<Scalars['String']>;
  pathGT?: InputMaybe<Scalars['String']>;
  pathGTE?: InputMaybe<Scalars['String']>;
  pathHasPrefix?: InputMaybe<Scalars['String']>;
  pathHasSuffix?: InputMaybe<Scalars['String']>;
  pathIn?: InputMaybe<Array<Scalars['String']>>;
  pathLT?: InputMaybe<Scalars['String']>;
  pathLTE?: InputMaybe<Scalars['String']>;
  pathNEQ?: InputMaybe<Scalars['String']>;
  pathNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** sha field predicates */
  sha?: InputMaybe<Scalars['String']>;
  shaContains?: InputMaybe<Scalars['String']>;
  shaContainsFold?: InputMaybe<Scalars['String']>;
  shaEqualFold?: InputMaybe<Scalars['String']>;
  shaGT?: InputMaybe<Scalars['String']>;
  shaGTE?: InputMaybe<Scalars['String']>;
  shaHasPrefix?: InputMaybe<Scalars['String']>;
  shaHasSuffix?: InputMaybe<Scalars['String']>;
  shaIn?: InputMaybe<Array<Scalars['String']>>;
  shaLT?: InputMaybe<Scalars['String']>;
  shaLTE?: InputMaybe<Scalars['String']>;
  shaNEQ?: InputMaybe<Scalars['String']>;
  shaNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** url field predicates */
  url?: InputMaybe<Scalars['String']>;
  urlContains?: InputMaybe<Scalars['String']>;
  urlContainsFold?: InputMaybe<Scalars['String']>;
  urlEqualFold?: InputMaybe<Scalars['String']>;
  urlGT?: InputMaybe<Scalars['String']>;
  urlGTE?: InputMaybe<Scalars['String']>;
  urlHasPrefix?: InputMaybe<Scalars['String']>;
  urlHasSuffix?: InputMaybe<Scalars['String']>;
  urlIn?: InputMaybe<Array<Scalars['String']>>;
  urlLT?: InputMaybe<Scalars['String']>;
  urlLTE?: InputMaybe<Scalars['String']>;
  urlNEQ?: InputMaybe<Scalars['String']>;
  urlNotIn?: InputMaybe<Array<Scalars['String']>>;
};

export type ComplianceControl = Node & {
  __typename?: 'ComplianceControl';
  complianceDirectives?: Maybe<Array<ComplianceDirective>>;
  complianceFramework?: Maybe<ComplianceFramework>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  name: Scalars['String'];
  tenant: Tenant;
  updatedAt: Scalars['Time'];
};

/** Ordering options for ComplianceControl connections */
export type ComplianceControlOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ComplianceControls. */
  field: ComplianceControlOrderField;
};

/** Properties by which ComplianceControl connections can be ordered. */
export enum ComplianceControlOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * ComplianceControlWhereInput is used for filtering ComplianceControl objects.
 * Input was generated by ent.
 */
export type ComplianceControlWhereInput = {
  and?: InputMaybe<Array<ComplianceControlWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']>;
  descriptionContains?: InputMaybe<Scalars['String']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']>;
  descriptionGT?: InputMaybe<Scalars['String']>;
  descriptionGTE?: InputMaybe<Scalars['String']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']>>;
  descriptionLT?: InputMaybe<Scalars['String']>;
  descriptionLTE?: InputMaybe<Scalars['String']>;
  descriptionNEQ?: InputMaybe<Scalars['String']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** compliance_directives edge predicates */
  hasComplianceDirectives?: InputMaybe<Scalars['Boolean']>;
  hasComplianceDirectivesWith?: InputMaybe<Array<ComplianceDirectiveWhereInput>>;
  /** compliance_framework edge predicates */
  hasComplianceFramework?: InputMaybe<Scalars['Boolean']>;
  hasComplianceFrameworkWith?: InputMaybe<Array<ComplianceFrameworkWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<ComplianceControlWhereInput>;
  or?: InputMaybe<Array<ComplianceControlWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type ComplianceDirective = Node & {
  __typename?: 'ComplianceDirective';
  complianceControl?: Maybe<ComplianceControl>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  name: Scalars['String'];
  repos?: Maybe<Array<Repo>>;
  tenant: Tenant;
  updatedAt: Scalars['Time'];
};

/** Ordering options for ComplianceDirective connections */
export type ComplianceDirectiveOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ComplianceDirectives. */
  field: ComplianceDirectiveOrderField;
};

/** Properties by which ComplianceDirective connections can be ordered. */
export enum ComplianceDirectiveOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * ComplianceDirectiveWhereInput is used for filtering ComplianceDirective objects.
 * Input was generated by ent.
 */
export type ComplianceDirectiveWhereInput = {
  and?: InputMaybe<Array<ComplianceDirectiveWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']>;
  descriptionContains?: InputMaybe<Scalars['String']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']>;
  descriptionGT?: InputMaybe<Scalars['String']>;
  descriptionGTE?: InputMaybe<Scalars['String']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']>>;
  descriptionLT?: InputMaybe<Scalars['String']>;
  descriptionLTE?: InputMaybe<Scalars['String']>;
  descriptionNEQ?: InputMaybe<Scalars['String']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** compliance_control edge predicates */
  hasComplianceControl?: InputMaybe<Scalars['Boolean']>;
  hasComplianceControlWith?: InputMaybe<Array<ComplianceControlWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** repos edge predicates */
  hasRepos?: InputMaybe<Scalars['Boolean']>;
  hasReposWith?: InputMaybe<Array<RepoWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<ComplianceDirectiveWhereInput>;
  or?: InputMaybe<Array<ComplianceDirectiveWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type ComplianceFramework = Node & {
  __typename?: 'ComplianceFramework';
  complianceControls?: Maybe<Array<ComplianceControl>>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  name: Scalars['String'];
  tenant: Tenant;
  updatedAt: Scalars['Time'];
};

/** A connection to a list of items. */
export type ComplianceFrameworkConnection = {
  __typename?: 'ComplianceFrameworkConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ComplianceFrameworkEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ComplianceFrameworkEdge = {
  __typename?: 'ComplianceFrameworkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<ComplianceFramework>;
};

/** Ordering options for ComplianceFramework connections */
export type ComplianceFrameworkOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ComplianceFrameworks. */
  field: ComplianceFrameworkOrderField;
};

/** Properties by which ComplianceFramework connections can be ordered. */
export enum ComplianceFrameworkOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * ComplianceFrameworkWhereInput is used for filtering ComplianceFramework objects.
 * Input was generated by ent.
 */
export type ComplianceFrameworkWhereInput = {
  and?: InputMaybe<Array<ComplianceFrameworkWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']>;
  descriptionContains?: InputMaybe<Scalars['String']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']>;
  descriptionGT?: InputMaybe<Scalars['String']>;
  descriptionGTE?: InputMaybe<Scalars['String']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']>>;
  descriptionLT?: InputMaybe<Scalars['String']>;
  descriptionLTE?: InputMaybe<Scalars['String']>;
  descriptionNEQ?: InputMaybe<Scalars['String']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** compliance_controls edge predicates */
  hasComplianceControls?: InputMaybe<Scalars['Boolean']>;
  hasComplianceControlsWith?: InputMaybe<Array<ComplianceControlWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<ComplianceFrameworkWhereInput>;
  or?: InputMaybe<Array<ComplianceFrameworkWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

/**
 * CreateCAProviderInput is used for create CAProvider object.
 * Input was generated by ent.
 */
export type CreateCaProviderInput = {
  /** CA URL */
  caURL?: InputMaybe<Scalars['String']>;
  /** Cache expiry time for the provider data */
  cacheExp?: InputMaybe<Scalars['Time']>;
  certConstraintIDs?: InputMaybe<Array<Scalars['ID']>>;
  chainIDs?: InputMaybe<Array<Scalars['ID']>>;
  createdAt?: InputMaybe<Scalars['Time']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  functionaryStoreIDs?: InputMaybe<Array<Scalars['ID']>>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  /** Name of the CA provider */
  name: Scalars['String'];
  tenantID: Scalars['ID'];
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * CreateCertConstraintInput is used for create CertConstraint object.
 * Input was generated by ent.
 */
export type CreateCertConstraintInput = {
  caProviderID?: InputMaybe<Scalars['ID']>;
  /** Common name that the certificate's subject must have */
  commonName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Time']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  /** Additional details or context for the constraint */
  details?: InputMaybe<Scalars['String']>;
  /** DNS names that the certificate must have */
  dnsNames?: InputMaybe<Array<Scalars['String']>>;
  /** Email addresses that the certificate must have */
  emails?: InputMaybe<Array<Scalars['String']>>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  /** OID constraints in the form of key-value pairs */
  oidConstraints?: InputMaybe<Scalars['Map']>;
  /** Organizations that the certificate must have */
  organizations?: InputMaybe<Array<Scalars['String']>>;
  /** Array of Key IDs the signer's certificate must belong to to be trusted */
  roots?: InputMaybe<Array<Scalars['String']>>;
  tenantID: Scalars['ID'];
  updatedAt?: InputMaybe<Scalars['Time']>;
  /** URIs that the certificate must have */
  uris?: InputMaybe<Array<Scalars['String']>>;
};

/**
 * CreateDsseInput is used for create Dsse object.
 * Input was generated by ent.
 */
export type CreateDsseInput = {
  gitoidSha256: Scalars['String'];
  policyDefinitionIDs?: InputMaybe<Array<Scalars['ID']>>;
  policyReleaseIDs?: InputMaybe<Array<Scalars['ID']>>;
  repoIDs?: InputMaybe<Array<Scalars['ID']>>;
};

/**
 * CreateFilterSetInput is used for create FilterSet object.
 * Input was generated by ent.
 */
export type CreateFilterSetInput = {
  createdAt?: InputMaybe<Scalars['Time']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  description: Scalars['String'];
  filterIDs?: InputMaybe<Array<Scalars['ID']>>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  tenantID: Scalars['ID'];
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * CreateFunctionaryStoreInput is used for create FunctionaryStore object.
 * Input was generated by ent.
 */
export type CreateFunctionaryStoreInput = {
  caProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  createCaProviderChildren?: InputMaybe<Array<CreateCaProviderInput>>;
  createdAt?: InputMaybe<Scalars['Time']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  /** Description of the Functionary Store */
  desc?: InputMaybe<Scalars['String']>;
  kmsProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  /** Name of the Functionary Store */
  name: Scalars['String'];
  oidcProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  pkProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  tenantID: Scalars['ID'];
  tsaProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * CreateKMSProviderInput is used for create KMSProvider object.
 * Input was generated by ent.
 */
export type CreateKmsProviderInput = {
  /** Cache expiry time for the provider data */
  cacheExp?: InputMaybe<Scalars['Time']>;
  createdAt?: InputMaybe<Scalars['Time']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  functionaryStoreID?: InputMaybe<Scalars['ID']>;
  /** KMS URL */
  kmsURL: Scalars['String'];
  modifiedByID?: InputMaybe<Scalars['ID']>;
  publicKeyIDs?: InputMaybe<Array<Scalars['ID']>>;
  tenantID: Scalars['ID'];
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * CreateOIDCConstraintInput is used for create OIDCConstraint object.
 * Input was generated by ent.
 */
export type CreateOidcConstraintInput = {
  /** The audience claim for the OIDC token */
  audience: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Time']>;
  /** Custom claims that the OIDC token must have */
  customClaims?: InputMaybe<Scalars['Map']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  /** The issuer URL of the OIDC provider */
  issuer: Scalars['String'];
  modifiedByID?: InputMaybe<Scalars['ID']>;
  oidcProviderID?: InputMaybe<Scalars['ID']>;
  /** The subject claim for the OIDC token */
  subject?: InputMaybe<Scalars['String']>;
  tenantID: Scalars['ID'];
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * CreateOIDCProviderInput is used for create OIDCProvider object.
 * Input was generated by ent.
 */
export type CreateOidcProviderInput = {
  /** Client ID for the OIDC provider */
  clientID: Scalars['String'];
  /** Client Secret for the OIDC provider */
  clientSecret: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Time']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  functionaryStoreID?: InputMaybe<Scalars['ID']>;
  /** Issuer URL for the OIDC provider */
  issuer: Scalars['String'];
  /** JWKS URI for the OIDC provider */
  jwksURI: Scalars['String'];
  modifiedByID?: InputMaybe<Scalars['ID']>;
  /** Name of the OIDC provider */
  name: Scalars['String'];
  oidcConstraintIDs?: InputMaybe<Array<Scalars['ID']>>;
  publicKeyIDs?: InputMaybe<Array<Scalars['ID']>>;
  tenantID: Scalars['ID'];
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * CreatePKProviderInput is used for create PKProvider object.
 * Input was generated by ent.
 */
export type CreatePkProviderInput = {
  createdAt?: InputMaybe<Scalars['Time']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  /** Description of the PK provider */
  desc?: InputMaybe<Scalars['String']>;
  functionaryStoreIDs?: InputMaybe<Array<Scalars['ID']>>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  /** Name of the PK provider */
  name: Scalars['String'];
  publicKeyIDs?: InputMaybe<Array<Scalars['ID']>>;
  tenantID: Scalars['ID'];
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * CreatePolicyBindingInput is used for create PolicyBinding object.
 * Input was generated by ent.
 */
export type CreatePolicyBindingInput = {
  createdAt?: InputMaybe<Scalars['Time']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  policyDefinitionID?: InputMaybe<Scalars['ID']>;
  policyReleaseID?: InputMaybe<Scalars['ID']>;
  repoIDs?: InputMaybe<Array<Scalars['ID']>>;
  tenantID: Scalars['ID'];
  updatedAt?: InputMaybe<Scalars['Time']>;
  userID?: InputMaybe<Scalars['ID']>;
};

/**
 * CreatePolicyDefinitionInput is used for create PolicyDefinition object.
 * Input was generated by ent.
 */
export type CreatePolicyDefinitionInput = {
  createdAt?: InputMaybe<Scalars['Time']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  description: Scalars['String'];
  dssIDs?: InputMaybe<Array<Scalars['ID']>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  policyReleaseIDs?: InputMaybe<Array<Scalars['ID']>>;
  tenantID: Scalars['ID'];
  updatedAt?: InputMaybe<Scalars['Time']>;
  userID?: InputMaybe<Scalars['ID']>;
};

/**
 * CreatePolicyReleaseInput is used for create PolicyRelease object.
 * Input was generated by ent.
 */
export type CreatePolicyReleaseInput = {
  createdAt?: InputMaybe<Scalars['Time']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  dsseID?: InputMaybe<Scalars['ID']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  policyBindingIDs?: InputMaybe<Array<Scalars['ID']>>;
  policyDefinitionID?: InputMaybe<Scalars['ID']>;
  tag: Scalars['String'];
  tenantID: Scalars['ID'];
  updatedAt?: InputMaybe<Scalars['Time']>;
  userID?: InputMaybe<Scalars['ID']>;
};

/**
 * CreateRepoInput is used for create Repo object.
 * Input was generated by ent.
 */
export type CreateRepoInput = {
  branchIDs?: InputMaybe<Array<Scalars['ID']>>;
  complianceDirectiveIDs?: InputMaybe<Array<Scalars['ID']>>;
  createdAt?: InputMaybe<Scalars['Time']>;
  defaultBranchName: Scalars['String'];
  deletedAt?: InputMaybe<Scalars['Time']>;
  description: Scalars['String'];
  dssIDs?: InputMaybe<Array<Scalars['ID']>>;
  fromtimestamp: Scalars['String'];
  gitProviderRepoID: Scalars['String'];
  modifiedByID?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  owner: Scalars['String'];
  path: Scalars['String'];
  policyBindingIDs?: InputMaybe<Array<Scalars['ID']>>;
  tenantID: Scalars['ID'];
  type: RepoType;
  updatedAt?: InputMaybe<Scalars['Time']>;
  url: Scalars['String'];
  userIDs?: InputMaybe<Array<Scalars['ID']>>;
};

/**
 * CreateTSAProviderInput is used for create TSAProvider object.
 * Input was generated by ent.
 */
export type CreateTsaProviderInput = {
  /** Cache expiry time for the provider data */
  cacheExp?: InputMaybe<Scalars['Time']>;
  chainIDs?: InputMaybe<Array<Scalars['ID']>>;
  createdAt?: InputMaybe<Scalars['Time']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  functionaryStoreID?: InputMaybe<Scalars['ID']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  tenantID: Scalars['ID'];
  /** TSA URL */
  tsaURL: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * CreateTenantInput is used for create Tenant object.
 * Input was generated by ent.
 */
export type CreateTenantInput = {
  childIDs?: InputMaybe<Array<Scalars['ID']>>;
  createdAt?: InputMaybe<Scalars['Time']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  description: Scalars['String'];
  modifiedByID?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  parentID?: InputMaybe<Scalars['ID']>;
  type: TenantType;
  updatedAt?: InputMaybe<Scalars['Time']>;
  userIDs?: InputMaybe<Array<Scalars['ID']>>;
};

/**
 * CreateUserInput is used for create User object.
 * Input was generated by ent.
 */
export type CreateUserInput = {
  createdAt: Scalars['Time'];
  email?: InputMaybe<Scalars['String']>;
  identityID: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  policyBindingIDs?: InputMaybe<Array<Scalars['ID']>>;
  policyDefinitionIDs?: InputMaybe<Array<Scalars['ID']>>;
  policyReleaseIDs?: InputMaybe<Array<Scalars['ID']>>;
  tenantIDs?: InputMaybe<Array<Scalars['ID']>>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt: Scalars['Time'];
};

export type Dsse = Node & {
  __typename?: 'Dsse';
  gitoidSha256: Scalars['String'];
  id: Scalars['ID'];
  payloadDigests?: Maybe<Array<PayloadDigest>>;
  payloadType: Scalars['String'];
  policyDefinition?: Maybe<Array<PolicyDefinition>>;
  policyRelease?: Maybe<Array<PolicyRelease>>;
  repo?: Maybe<Array<Repo>>;
  signatures?: Maybe<Array<Signature>>;
  statement?: Maybe<Statement>;
  vulnSummary?: Maybe<Array<VulnSummary>>;
};

/** A connection to a list of items. */
export type DsseConnection = {
  __typename?: 'DsseConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DsseEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DsseEdge = {
  __typename?: 'DsseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<Dsse>;
};

/**
 * DsseWhereInput is used for filtering Dsse objects.
 * Input was generated by ent.
 */
export type DsseWhereInput = {
  and?: InputMaybe<Array<DsseWhereInput>>;
  /** gitoid_sha256 field predicates */
  gitoidSha256?: InputMaybe<Scalars['String']>;
  gitoidSha256Contains?: InputMaybe<Scalars['String']>;
  gitoidSha256ContainsFold?: InputMaybe<Scalars['String']>;
  gitoidSha256EqualFold?: InputMaybe<Scalars['String']>;
  gitoidSha256GT?: InputMaybe<Scalars['String']>;
  gitoidSha256GTE?: InputMaybe<Scalars['String']>;
  gitoidSha256HasPrefix?: InputMaybe<Scalars['String']>;
  gitoidSha256HasSuffix?: InputMaybe<Scalars['String']>;
  gitoidSha256In?: InputMaybe<Array<Scalars['String']>>;
  gitoidSha256LT?: InputMaybe<Scalars['String']>;
  gitoidSha256LTE?: InputMaybe<Scalars['String']>;
  gitoidSha256NEQ?: InputMaybe<Scalars['String']>;
  gitoidSha256NotIn?: InputMaybe<Array<Scalars['String']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  not?: InputMaybe<DsseWhereInput>;
  or?: InputMaybe<Array<DsseWhereInput>>;
};

export type Filter = Node & {
  __typename?: 'Filter';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  /** The feature this filter is checking. */
  feature: Scalars['String'];
  filterSet: FilterSet;
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  updatedAt: Scalars['Time'];
  /** The value of the feature this filter is checking. */
  value: Scalars['String'];
};

/** Ordering options for Filter connections */
export type FilterOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Filters. */
  field: FilterOrderField;
};

/** Properties by which Filter connections can be ordered. */
export enum FilterOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Feature = 'FEATURE',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
  Value = 'VALUE',
}

export type FilterSet = Node & {
  __typename?: 'FilterSet';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  filters?: Maybe<Array<Filter>>;
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  name: Scalars['String'];
  tenant: Tenant;
  updatedAt: Scalars['Time'];
};

/** Ordering options for FilterSet connections */
export type FilterSetOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order FilterSets. */
  field: FilterSetOrderField;
};

/** Properties by which FilterSet connections can be ordered. */
export enum FilterSetOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Description = 'DESCRIPTION',
  Id = 'ID',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * FilterSetWhereInput is used for filtering FilterSet objects.
 * Input was generated by ent.
 */
export type FilterSetWhereInput = {
  and?: InputMaybe<Array<FilterSetWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']>;
  descriptionContains?: InputMaybe<Scalars['String']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']>;
  descriptionGT?: InputMaybe<Scalars['String']>;
  descriptionGTE?: InputMaybe<Scalars['String']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']>>;
  descriptionLT?: InputMaybe<Scalars['String']>;
  descriptionLTE?: InputMaybe<Scalars['String']>;
  descriptionNEQ?: InputMaybe<Scalars['String']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** filters edge predicates */
  hasFilters?: InputMaybe<Scalars['Boolean']>;
  hasFiltersWith?: InputMaybe<Array<FilterWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<FilterSetWhereInput>;
  or?: InputMaybe<Array<FilterSetWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

/**
 * FilterWhereInput is used for filtering Filter objects.
 * Input was generated by ent.
 */
export type FilterWhereInput = {
  and?: InputMaybe<Array<FilterWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** feature field predicates */
  feature?: InputMaybe<Scalars['String']>;
  featureContains?: InputMaybe<Scalars['String']>;
  featureContainsFold?: InputMaybe<Scalars['String']>;
  featureEqualFold?: InputMaybe<Scalars['String']>;
  featureGT?: InputMaybe<Scalars['String']>;
  featureGTE?: InputMaybe<Scalars['String']>;
  featureHasPrefix?: InputMaybe<Scalars['String']>;
  featureHasSuffix?: InputMaybe<Scalars['String']>;
  featureIn?: InputMaybe<Array<Scalars['String']>>;
  featureLT?: InputMaybe<Scalars['String']>;
  featureLTE?: InputMaybe<Scalars['String']>;
  featureNEQ?: InputMaybe<Scalars['String']>;
  featureNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** filter_set edge predicates */
  hasFilterSet?: InputMaybe<Scalars['Boolean']>;
  hasFilterSetWith?: InputMaybe<Array<FilterSetWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  not?: InputMaybe<FilterWhereInput>;
  or?: InputMaybe<Array<FilterWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** value field predicates */
  value?: InputMaybe<Scalars['String']>;
  valueContains?: InputMaybe<Scalars['String']>;
  valueContainsFold?: InputMaybe<Scalars['String']>;
  valueEqualFold?: InputMaybe<Scalars['String']>;
  valueGT?: InputMaybe<Scalars['String']>;
  valueGTE?: InputMaybe<Scalars['String']>;
  valueHasPrefix?: InputMaybe<Scalars['String']>;
  valueHasSuffix?: InputMaybe<Scalars['String']>;
  valueIn?: InputMaybe<Array<Scalars['String']>>;
  valueLT?: InputMaybe<Scalars['String']>;
  valueLTE?: InputMaybe<Scalars['String']>;
  valueNEQ?: InputMaybe<Scalars['String']>;
  valueNotIn?: InputMaybe<Array<Scalars['String']>>;
};

export type FunctionaryStore = Node & {
  __typename?: 'FunctionaryStore';
  caProviders?: Maybe<Array<CaProvider>>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  /** Description of the Functionary Store */
  desc?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kmsProviders?: Maybe<Array<KmsProvider>>;
  modifiedBy?: Maybe<User>;
  /** Name of the Functionary Store */
  name: Scalars['String'];
  oidcProviders?: Maybe<Array<OidcProvider>>;
  pkProviders?: Maybe<Array<PkProvider>>;
  tenant: Tenant;
  tsaProviders?: Maybe<Array<TsaProvider>>;
  updatedAt: Scalars['Time'];
};

/** A connection to a list of items. */
export type FunctionaryStoreConnection = {
  __typename?: 'FunctionaryStoreConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FunctionaryStoreEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type FunctionaryStoreEdge = {
  __typename?: 'FunctionaryStoreEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<FunctionaryStore>;
};

/** Ordering options for FunctionaryStore connections */
export type FunctionaryStoreOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order FunctionaryStores. */
  field: FunctionaryStoreOrderField;
};

/** Properties by which FunctionaryStore connections can be ordered. */
export enum FunctionaryStoreOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * FunctionaryStoreWhereInput is used for filtering FunctionaryStore objects.
 * Input was generated by ent.
 */
export type FunctionaryStoreWhereInput = {
  and?: InputMaybe<Array<FunctionaryStoreWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** desc field predicates */
  desc?: InputMaybe<Scalars['String']>;
  descContains?: InputMaybe<Scalars['String']>;
  descContainsFold?: InputMaybe<Scalars['String']>;
  descEqualFold?: InputMaybe<Scalars['String']>;
  descGT?: InputMaybe<Scalars['String']>;
  descGTE?: InputMaybe<Scalars['String']>;
  descHasPrefix?: InputMaybe<Scalars['String']>;
  descHasSuffix?: InputMaybe<Scalars['String']>;
  descIn?: InputMaybe<Array<Scalars['String']>>;
  descIsNil?: InputMaybe<Scalars['Boolean']>;
  descLT?: InputMaybe<Scalars['String']>;
  descLTE?: InputMaybe<Scalars['String']>;
  descNEQ?: InputMaybe<Scalars['String']>;
  descNotIn?: InputMaybe<Array<Scalars['String']>>;
  descNotNil?: InputMaybe<Scalars['Boolean']>;
  /** ca_providers edge predicates */
  hasCaProviders?: InputMaybe<Scalars['Boolean']>;
  hasCaProvidersWith?: InputMaybe<Array<CaProviderWhereInput>>;
  /** kms_providers edge predicates */
  hasKmsProviders?: InputMaybe<Scalars['Boolean']>;
  hasKmsProvidersWith?: InputMaybe<Array<KmsProviderWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** oidc_providers edge predicates */
  hasOidcProviders?: InputMaybe<Scalars['Boolean']>;
  hasOidcProvidersWith?: InputMaybe<Array<OidcProviderWhereInput>>;
  /** pk_providers edge predicates */
  hasPkProviders?: InputMaybe<Scalars['Boolean']>;
  hasPkProvidersWith?: InputMaybe<Array<PkProviderWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** tsa_providers edge predicates */
  hasTsaProviders?: InputMaybe<Scalars['Boolean']>;
  hasTsaProvidersWith?: InputMaybe<Array<TsaProviderWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<FunctionaryStoreWhereInput>;
  or?: InputMaybe<Array<FunctionaryStoreWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type KmsProvider = Node & {
  __typename?: 'KMSProvider';
  /** Cache expiry time for the provider data */
  cacheExp?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  functionaryStore?: Maybe<FunctionaryStore>;
  id: Scalars['ID'];
  /** KMS URL */
  kmsURL: Scalars['String'];
  modifiedBy?: Maybe<User>;
  publicKeys?: Maybe<Array<PublicKey>>;
  tenant: Tenant;
  updatedAt: Scalars['Time'];
};

/** Ordering options for KMSProvider connections */
export type KmsProviderOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order KMSProviders. */
  field: KmsProviderOrderField;
};

/** Properties by which KMSProvider connections can be ordered. */
export enum KmsProviderOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * KMSProviderWhereInput is used for filtering KMSProvider objects.
 * Input was generated by ent.
 */
export type KmsProviderWhereInput = {
  and?: InputMaybe<Array<KmsProviderWhereInput>>;
  /** cache_exp field predicates */
  cacheExp?: InputMaybe<Scalars['Time']>;
  cacheExpGT?: InputMaybe<Scalars['Time']>;
  cacheExpGTE?: InputMaybe<Scalars['Time']>;
  cacheExpIn?: InputMaybe<Array<Scalars['Time']>>;
  cacheExpIsNil?: InputMaybe<Scalars['Boolean']>;
  cacheExpLT?: InputMaybe<Scalars['Time']>;
  cacheExpLTE?: InputMaybe<Scalars['Time']>;
  cacheExpNEQ?: InputMaybe<Scalars['Time']>;
  cacheExpNotIn?: InputMaybe<Array<Scalars['Time']>>;
  cacheExpNotNil?: InputMaybe<Scalars['Boolean']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** functionary_store edge predicates */
  hasFunctionaryStore?: InputMaybe<Scalars['Boolean']>;
  hasFunctionaryStoreWith?: InputMaybe<Array<FunctionaryStoreWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** public_keys edge predicates */
  hasPublicKeys?: InputMaybe<Scalars['Boolean']>;
  hasPublicKeysWith?: InputMaybe<Array<PublicKeyWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** kms_url field predicates */
  kmsURL?: InputMaybe<Scalars['String']>;
  kmsURLContains?: InputMaybe<Scalars['String']>;
  kmsURLContainsFold?: InputMaybe<Scalars['String']>;
  kmsURLEqualFold?: InputMaybe<Scalars['String']>;
  kmsURLGT?: InputMaybe<Scalars['String']>;
  kmsURLGTE?: InputMaybe<Scalars['String']>;
  kmsURLHasPrefix?: InputMaybe<Scalars['String']>;
  kmsURLHasSuffix?: InputMaybe<Scalars['String']>;
  kmsURLIn?: InputMaybe<Array<Scalars['String']>>;
  kmsURLLT?: InputMaybe<Scalars['String']>;
  kmsURLLTE?: InputMaybe<Scalars['String']>;
  kmsURLNEQ?: InputMaybe<Scalars['String']>;
  kmsURLNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<KmsProviderWhereInput>;
  or?: InputMaybe<Array<KmsProviderWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCAProvider: CaProvider;
  createFunctionaryStore: FunctionaryStore;
  createKMSProvider: KmsProvider;
  createOIDCProvider: OidcProvider;
  createPKProvider: PkProvider;
  createPolicyDefinition: PolicyDefinition;
  createRepo: Repo;
  createTSAProvider: TsaProvider;
  createTenant: Tenant;
  createUser: User;
  deleteRepo: Repo;
  updateFunctionaryStore: FunctionaryStore;
  updatePolicyDefinition: PolicyDefinition;
  updateRepo: Repo;
  updateTenant: Tenant;
  updateUser: User;
};

export type MutationCreateCaProviderArgs = {
  caProviderData: CreateCaProviderInput;
  certificateConstraintData?: InputMaybe<CreateCertConstraintInput>;
  pemChain: Scalars['String'];
};

export type MutationCreateFunctionaryStoreArgs = {
  FunctionaryStoreData: CreateFunctionaryStoreInput;
};

export type MutationCreateKmsProviderArgs = {
  kmsProviderData: CreateKmsProviderInput;
};

export type MutationCreateOidcProviderArgs = {
  oidcConstraintData?: InputMaybe<CreateOidcConstraintInput>;
  oidcProviderData: CreateOidcProviderInput;
};

export type MutationCreatePkProviderArgs = {
  pemPublicKey: Scalars['String'];
  pkProviderData: CreatePkProviderInput;
};

export type MutationCreatePolicyDefinitionArgs = {
  input: CreatePolicyDefinitionInput;
};

export type MutationCreateRepoArgs = {
  input: CreateRepoInput;
};

export type MutationCreateTsaProviderArgs = {
  pemChain: Scalars['String'];
  tsaProviderData: CreateTsaProviderInput;
};

export type MutationCreateTenantArgs = {
  input: CreateTenantInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteRepoArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateFunctionaryStoreArgs = {
  FunctionaryStoreData: UpdateFunctionaryStoreInput;
  id: Scalars['ID'];
};

export type MutationUpdatePolicyDefinitionArgs = {
  input: UpdatePolicyDefinitionInput;
};

export type MutationUpdateRepoArgs = {
  id: Scalars['ID'];
  input: UpdateRepoInput;
};

export type MutationUpdateTenantArgs = {
  input: UpdateTenantInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/**
 * An object with an ID.
 * Follows the [Relay Global Object Identification Specification](https://relay.dev/graphql/objectidentification.htm)
 */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'];
};

export type OidcConstraint = Node & {
  __typename?: 'OIDCConstraint';
  /** The audience claim for the OIDC token */
  audience: Scalars['String'];
  createdAt: Scalars['Time'];
  /** Custom claims that the OIDC token must have */
  customClaims?: Maybe<Scalars['Map']>;
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  /** The issuer URL of the OIDC provider */
  issuer: Scalars['String'];
  modifiedBy?: Maybe<User>;
  oidcProvider?: Maybe<OidcProvider>;
  /** The subject claim for the OIDC token */
  subject?: Maybe<Scalars['String']>;
  tenant: Tenant;
  updatedAt: Scalars['Time'];
};

/** Ordering options for OIDCConstraint connections */
export type OidcConstraintOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OIDCConstraints. */
  field: OidcConstraintOrderField;
};

/** Properties by which OIDCConstraint connections can be ordered. */
export enum OidcConstraintOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * OIDCConstraintWhereInput is used for filtering OIDCConstraint objects.
 * Input was generated by ent.
 */
export type OidcConstraintWhereInput = {
  and?: InputMaybe<Array<OidcConstraintWhereInput>>;
  /** audience field predicates */
  audience?: InputMaybe<Scalars['String']>;
  audienceContains?: InputMaybe<Scalars['String']>;
  audienceContainsFold?: InputMaybe<Scalars['String']>;
  audienceEqualFold?: InputMaybe<Scalars['String']>;
  audienceGT?: InputMaybe<Scalars['String']>;
  audienceGTE?: InputMaybe<Scalars['String']>;
  audienceHasPrefix?: InputMaybe<Scalars['String']>;
  audienceHasSuffix?: InputMaybe<Scalars['String']>;
  audienceIn?: InputMaybe<Array<Scalars['String']>>;
  audienceLT?: InputMaybe<Scalars['String']>;
  audienceLTE?: InputMaybe<Scalars['String']>;
  audienceNEQ?: InputMaybe<Scalars['String']>;
  audienceNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** oidc_provider edge predicates */
  hasOidcProvider?: InputMaybe<Scalars['Boolean']>;
  hasOidcProviderWith?: InputMaybe<Array<OidcProviderWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** issuer field predicates */
  issuer?: InputMaybe<Scalars['String']>;
  issuerContains?: InputMaybe<Scalars['String']>;
  issuerContainsFold?: InputMaybe<Scalars['String']>;
  issuerEqualFold?: InputMaybe<Scalars['String']>;
  issuerGT?: InputMaybe<Scalars['String']>;
  issuerGTE?: InputMaybe<Scalars['String']>;
  issuerHasPrefix?: InputMaybe<Scalars['String']>;
  issuerHasSuffix?: InputMaybe<Scalars['String']>;
  issuerIn?: InputMaybe<Array<Scalars['String']>>;
  issuerLT?: InputMaybe<Scalars['String']>;
  issuerLTE?: InputMaybe<Scalars['String']>;
  issuerNEQ?: InputMaybe<Scalars['String']>;
  issuerNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<OidcConstraintWhereInput>;
  or?: InputMaybe<Array<OidcConstraintWhereInput>>;
  /** subject field predicates */
  subject?: InputMaybe<Scalars['String']>;
  subjectContains?: InputMaybe<Scalars['String']>;
  subjectContainsFold?: InputMaybe<Scalars['String']>;
  subjectEqualFold?: InputMaybe<Scalars['String']>;
  subjectGT?: InputMaybe<Scalars['String']>;
  subjectGTE?: InputMaybe<Scalars['String']>;
  subjectHasPrefix?: InputMaybe<Scalars['String']>;
  subjectHasSuffix?: InputMaybe<Scalars['String']>;
  subjectIn?: InputMaybe<Array<Scalars['String']>>;
  subjectIsNil?: InputMaybe<Scalars['Boolean']>;
  subjectLT?: InputMaybe<Scalars['String']>;
  subjectLTE?: InputMaybe<Scalars['String']>;
  subjectNEQ?: InputMaybe<Scalars['String']>;
  subjectNotIn?: InputMaybe<Array<Scalars['String']>>;
  subjectNotNil?: InputMaybe<Scalars['Boolean']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type OidcProvider = Node & {
  __typename?: 'OIDCProvider';
  /** Client ID for the OIDC provider */
  clientID: Scalars['String'];
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  functionaryStore?: Maybe<FunctionaryStore>;
  id: Scalars['ID'];
  /** Issuer URL for the OIDC provider */
  issuer: Scalars['String'];
  /** JWKS URI for the OIDC provider */
  jwksURI: Scalars['String'];
  modifiedBy?: Maybe<User>;
  /** Name of the OIDC provider */
  name: Scalars['String'];
  oidcConstraints?: Maybe<Array<OidcConstraint>>;
  publicKeys?: Maybe<Array<PublicKey>>;
  tenant: Tenant;
  updatedAt: Scalars['Time'];
};

/** Ordering options for OIDCProvider connections */
export type OidcProviderOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OIDCProviders. */
  field: OidcProviderOrderField;
};

/** Properties by which OIDCProvider connections can be ordered. */
export enum OidcProviderOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * OIDCProviderWhereInput is used for filtering OIDCProvider objects.
 * Input was generated by ent.
 */
export type OidcProviderWhereInput = {
  and?: InputMaybe<Array<OidcProviderWhereInput>>;
  /** client_id field predicates */
  clientID?: InputMaybe<Scalars['String']>;
  clientIDContains?: InputMaybe<Scalars['String']>;
  clientIDContainsFold?: InputMaybe<Scalars['String']>;
  clientIDEqualFold?: InputMaybe<Scalars['String']>;
  clientIDGT?: InputMaybe<Scalars['String']>;
  clientIDGTE?: InputMaybe<Scalars['String']>;
  clientIDHasPrefix?: InputMaybe<Scalars['String']>;
  clientIDHasSuffix?: InputMaybe<Scalars['String']>;
  clientIDIn?: InputMaybe<Array<Scalars['String']>>;
  clientIDLT?: InputMaybe<Scalars['String']>;
  clientIDLTE?: InputMaybe<Scalars['String']>;
  clientIDNEQ?: InputMaybe<Scalars['String']>;
  clientIDNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** functionary_store edge predicates */
  hasFunctionaryStore?: InputMaybe<Scalars['Boolean']>;
  hasFunctionaryStoreWith?: InputMaybe<Array<FunctionaryStoreWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** oidc_constraints edge predicates */
  hasOidcConstraints?: InputMaybe<Scalars['Boolean']>;
  hasOidcConstraintsWith?: InputMaybe<Array<OidcConstraintWhereInput>>;
  /** public_keys edge predicates */
  hasPublicKeys?: InputMaybe<Scalars['Boolean']>;
  hasPublicKeysWith?: InputMaybe<Array<PublicKeyWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** issuer field predicates */
  issuer?: InputMaybe<Scalars['String']>;
  issuerContains?: InputMaybe<Scalars['String']>;
  issuerContainsFold?: InputMaybe<Scalars['String']>;
  issuerEqualFold?: InputMaybe<Scalars['String']>;
  issuerGT?: InputMaybe<Scalars['String']>;
  issuerGTE?: InputMaybe<Scalars['String']>;
  issuerHasPrefix?: InputMaybe<Scalars['String']>;
  issuerHasSuffix?: InputMaybe<Scalars['String']>;
  issuerIn?: InputMaybe<Array<Scalars['String']>>;
  issuerLT?: InputMaybe<Scalars['String']>;
  issuerLTE?: InputMaybe<Scalars['String']>;
  issuerNEQ?: InputMaybe<Scalars['String']>;
  issuerNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** jwks_uri field predicates */
  jwksURI?: InputMaybe<Scalars['String']>;
  jwksURIContains?: InputMaybe<Scalars['String']>;
  jwksURIContainsFold?: InputMaybe<Scalars['String']>;
  jwksURIEqualFold?: InputMaybe<Scalars['String']>;
  jwksURIGT?: InputMaybe<Scalars['String']>;
  jwksURIGTE?: InputMaybe<Scalars['String']>;
  jwksURIHasPrefix?: InputMaybe<Scalars['String']>;
  jwksURIHasSuffix?: InputMaybe<Scalars['String']>;
  jwksURIIn?: InputMaybe<Array<Scalars['String']>>;
  jwksURILT?: InputMaybe<Scalars['String']>;
  jwksURILTE?: InputMaybe<Scalars['String']>;
  jwksURINEQ?: InputMaybe<Scalars['String']>;
  jwksURINotIn?: InputMaybe<Array<Scalars['String']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<OidcProviderWhereInput>;
  or?: InputMaybe<Array<OidcProviderWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  /** Specifies an ascending order for a given `orderBy` argument. */
  Asc = 'ASC',
  /** Specifies a descending order for a given `orderBy` argument. */
  Desc = 'DESC',
}

export type PkProvider = Node & {
  __typename?: 'PKProvider';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  /** Description of the PK provider */
  desc?: Maybe<Scalars['String']>;
  functionaryStore?: Maybe<Array<FunctionaryStore>>;
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  /** Name of the PK provider */
  name: Scalars['String'];
  publicKeys?: Maybe<Array<PublicKey>>;
  tenant: Tenant;
  updatedAt: Scalars['Time'];
};

/** Ordering options for PKProvider connections */
export type PkProviderOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order PKProviders. */
  field: PkProviderOrderField;
};

/** Properties by which PKProvider connections can be ordered. */
export enum PkProviderOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * PKProviderWhereInput is used for filtering PKProvider objects.
 * Input was generated by ent.
 */
export type PkProviderWhereInput = {
  and?: InputMaybe<Array<PkProviderWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** desc field predicates */
  desc?: InputMaybe<Scalars['String']>;
  descContains?: InputMaybe<Scalars['String']>;
  descContainsFold?: InputMaybe<Scalars['String']>;
  descEqualFold?: InputMaybe<Scalars['String']>;
  descGT?: InputMaybe<Scalars['String']>;
  descGTE?: InputMaybe<Scalars['String']>;
  descHasPrefix?: InputMaybe<Scalars['String']>;
  descHasSuffix?: InputMaybe<Scalars['String']>;
  descIn?: InputMaybe<Array<Scalars['String']>>;
  descIsNil?: InputMaybe<Scalars['Boolean']>;
  descLT?: InputMaybe<Scalars['String']>;
  descLTE?: InputMaybe<Scalars['String']>;
  descNEQ?: InputMaybe<Scalars['String']>;
  descNotIn?: InputMaybe<Array<Scalars['String']>>;
  descNotNil?: InputMaybe<Scalars['Boolean']>;
  /** functionary_store edge predicates */
  hasFunctionaryStore?: InputMaybe<Scalars['Boolean']>;
  hasFunctionaryStoreWith?: InputMaybe<Array<FunctionaryStoreWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** public_keys edge predicates */
  hasPublicKeys?: InputMaybe<Scalars['Boolean']>;
  hasPublicKeysWith?: InputMaybe<Array<PublicKeyWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<PkProviderWhereInput>;
  or?: InputMaybe<Array<PkProviderWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

/**
 * Information about pagination in a connection.
 * https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo
 */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type PayloadDigest = Node & {
  __typename?: 'PayloadDigest';
  algorithm: Scalars['String'];
  dsse?: Maybe<Dsse>;
  id: Scalars['ID'];
  value: Scalars['String'];
};

/**
 * PayloadDigestWhereInput is used for filtering PayloadDigest objects.
 * Input was generated by ent.
 */
export type PayloadDigestWhereInput = {
  /** algorithm field predicates */
  algorithm?: InputMaybe<Scalars['String']>;
  algorithmContains?: InputMaybe<Scalars['String']>;
  algorithmContainsFold?: InputMaybe<Scalars['String']>;
  algorithmEqualFold?: InputMaybe<Scalars['String']>;
  algorithmGT?: InputMaybe<Scalars['String']>;
  algorithmGTE?: InputMaybe<Scalars['String']>;
  algorithmHasPrefix?: InputMaybe<Scalars['String']>;
  algorithmHasSuffix?: InputMaybe<Scalars['String']>;
  algorithmIn?: InputMaybe<Array<Scalars['String']>>;
  algorithmLT?: InputMaybe<Scalars['String']>;
  algorithmLTE?: InputMaybe<Scalars['String']>;
  algorithmNEQ?: InputMaybe<Scalars['String']>;
  algorithmNotIn?: InputMaybe<Array<Scalars['String']>>;
  and?: InputMaybe<Array<PayloadDigestWhereInput>>;
  /** dsse edge predicates */
  hasDsse?: InputMaybe<Scalars['Boolean']>;
  hasDsseWith?: InputMaybe<Array<DsseWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  not?: InputMaybe<PayloadDigestWhereInput>;
  or?: InputMaybe<Array<PayloadDigestWhereInput>>;
  /** value field predicates */
  value?: InputMaybe<Scalars['String']>;
  valueContains?: InputMaybe<Scalars['String']>;
  valueContainsFold?: InputMaybe<Scalars['String']>;
  valueEqualFold?: InputMaybe<Scalars['String']>;
  valueGT?: InputMaybe<Scalars['String']>;
  valueGTE?: InputMaybe<Scalars['String']>;
  valueHasPrefix?: InputMaybe<Scalars['String']>;
  valueHasSuffix?: InputMaybe<Scalars['String']>;
  valueIn?: InputMaybe<Array<Scalars['String']>>;
  valueLT?: InputMaybe<Scalars['String']>;
  valueLTE?: InputMaybe<Scalars['String']>;
  valueNEQ?: InputMaybe<Scalars['String']>;
  valueNotIn?: InputMaybe<Array<Scalars['String']>>;
};

export type PolicyBinding = Node & {
  __typename?: 'PolicyBinding';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  policyDefinition?: Maybe<PolicyDefinition>;
  policyRelease?: Maybe<PolicyRelease>;
  repos?: Maybe<Array<Repo>>;
  tenant: Tenant;
  updatedAt: Scalars['Time'];
  user?: Maybe<User>;
};

/** A connection to a list of items. */
export type PolicyBindingConnection = {
  __typename?: 'PolicyBindingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PolicyBindingEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PolicyBindingEdge = {
  __typename?: 'PolicyBindingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<PolicyBinding>;
};

/** Ordering options for PolicyBinding connections */
export type PolicyBindingOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order PolicyBindings. */
  field: PolicyBindingOrderField;
};

/** Properties by which PolicyBinding connections can be ordered. */
export enum PolicyBindingOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * PolicyBindingWhereInput is used for filtering PolicyBinding objects.
 * Input was generated by ent.
 */
export type PolicyBindingWhereInput = {
  and?: InputMaybe<Array<PolicyBindingWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** policy_definition edge predicates */
  hasPolicyDefinition?: InputMaybe<Scalars['Boolean']>;
  hasPolicyDefinitionWith?: InputMaybe<Array<PolicyDefinitionWhereInput>>;
  /** policy_release edge predicates */
  hasPolicyRelease?: InputMaybe<Scalars['Boolean']>;
  hasPolicyReleaseWith?: InputMaybe<Array<PolicyReleaseWhereInput>>;
  /** repos edge predicates */
  hasRepos?: InputMaybe<Scalars['Boolean']>;
  hasReposWith?: InputMaybe<Array<RepoWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  not?: InputMaybe<PolicyBindingWhereInput>;
  or?: InputMaybe<Array<PolicyBindingWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type PolicyDefinition = Node & {
  __typename?: 'PolicyDefinition';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  dsses?: Maybe<Array<Dsse>>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  modifiedBy?: Maybe<User>;
  name: Scalars['String'];
  policyRelease?: Maybe<Array<PolicyRelease>>;
  tenant: Tenant;
  updatedAt: Scalars['Time'];
  user?: Maybe<User>;
};

/** A connection to a list of items. */
export type PolicyDefinitionConnection = {
  __typename?: 'PolicyDefinitionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PolicyDefinitionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PolicyDefinitionEdge = {
  __typename?: 'PolicyDefinitionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<PolicyDefinition>;
};

/** Ordering options for PolicyDefinition connections */
export type PolicyDefinitionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order PolicyDefinitions. */
  field: PolicyDefinitionOrderField;
};

/** Properties by which PolicyDefinition connections can be ordered. */
export enum PolicyDefinitionOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * PolicyDefinitionWhereInput is used for filtering PolicyDefinition objects.
 * Input was generated by ent.
 */
export type PolicyDefinitionWhereInput = {
  and?: InputMaybe<Array<PolicyDefinitionWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']>;
  descriptionContains?: InputMaybe<Scalars['String']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']>;
  descriptionGT?: InputMaybe<Scalars['String']>;
  descriptionGTE?: InputMaybe<Scalars['String']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']>>;
  descriptionLT?: InputMaybe<Scalars['String']>;
  descriptionLTE?: InputMaybe<Scalars['String']>;
  descriptionNEQ?: InputMaybe<Scalars['String']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** dsses edge predicates */
  hasDsses?: InputMaybe<Scalars['Boolean']>;
  hasDssesWith?: InputMaybe<Array<DsseWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** policy_release edge predicates */
  hasPolicyRelease?: InputMaybe<Scalars['Boolean']>;
  hasPolicyReleaseWith?: InputMaybe<Array<PolicyReleaseWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** is_active field predicates */
  isActive?: InputMaybe<Scalars['Boolean']>;
  isActiveNEQ?: InputMaybe<Scalars['Boolean']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<PolicyDefinitionWhereInput>;
  or?: InputMaybe<Array<PolicyDefinitionWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type PolicyRelease = Node & {
  __typename?: 'PolicyRelease';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  dsse?: Maybe<Dsse>;
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  policyBinding?: Maybe<Array<PolicyBinding>>;
  policyDefinition?: Maybe<PolicyDefinition>;
  tag: Scalars['String'];
  tenant: Tenant;
  updatedAt: Scalars['Time'];
  user?: Maybe<User>;
};

/** A connection to a list of items. */
export type PolicyReleaseConnection = {
  __typename?: 'PolicyReleaseConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PolicyReleaseEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PolicyReleaseEdge = {
  __typename?: 'PolicyReleaseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<PolicyRelease>;
};

/** Ordering options for PolicyRelease connections */
export type PolicyReleaseOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order PolicyReleases. */
  field: PolicyReleaseOrderField;
};

/** Properties by which PolicyRelease connections can be ordered. */
export enum PolicyReleaseOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * PolicyReleaseWhereInput is used for filtering PolicyRelease objects.
 * Input was generated by ent.
 */
export type PolicyReleaseWhereInput = {
  and?: InputMaybe<Array<PolicyReleaseWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** dsse edge predicates */
  hasDsse?: InputMaybe<Scalars['Boolean']>;
  hasDsseWith?: InputMaybe<Array<DsseWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** policy_binding edge predicates */
  hasPolicyBinding?: InputMaybe<Scalars['Boolean']>;
  hasPolicyBindingWith?: InputMaybe<Array<PolicyBindingWhereInput>>;
  /** policy_definition edge predicates */
  hasPolicyDefinition?: InputMaybe<Scalars['Boolean']>;
  hasPolicyDefinitionWith?: InputMaybe<Array<PolicyDefinitionWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  not?: InputMaybe<PolicyReleaseWhereInput>;
  or?: InputMaybe<Array<PolicyReleaseWhereInput>>;
  /** tag field predicates */
  tag?: InputMaybe<Scalars['String']>;
  tagContains?: InputMaybe<Scalars['String']>;
  tagContainsFold?: InputMaybe<Scalars['String']>;
  tagEqualFold?: InputMaybe<Scalars['String']>;
  tagGT?: InputMaybe<Scalars['String']>;
  tagGTE?: InputMaybe<Scalars['String']>;
  tagHasPrefix?: InputMaybe<Scalars['String']>;
  tagHasSuffix?: InputMaybe<Scalars['String']>;
  tagIn?: InputMaybe<Array<Scalars['String']>>;
  tagLT?: InputMaybe<Scalars['String']>;
  tagLTE?: InputMaybe<Scalars['String']>;
  tagNEQ?: InputMaybe<Scalars['String']>;
  tagNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type Project = Node & {
  __typename?: 'Project';
  createdAt: Scalars['Time'];
  defaultBranchName: Scalars['String'];
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  name: Scalars['String'];
  projecturl: Scalars['String'];
  repoID: Scalars['String'];
  tenant: Tenant;
  updatedAt: Scalars['Time'];
};

/** Ordering options for Project connections */
export type ProjectOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Projects. */
  field: ProjectOrderField;
};

/** Properties by which Project connections can be ordered. */
export enum ProjectOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * ProjectWhereInput is used for filtering Project objects.
 * Input was generated by ent.
 */
export type ProjectWhereInput = {
  and?: InputMaybe<Array<ProjectWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** default_branch_name field predicates */
  defaultBranchName?: InputMaybe<Scalars['String']>;
  defaultBranchNameContains?: InputMaybe<Scalars['String']>;
  defaultBranchNameContainsFold?: InputMaybe<Scalars['String']>;
  defaultBranchNameEqualFold?: InputMaybe<Scalars['String']>;
  defaultBranchNameGT?: InputMaybe<Scalars['String']>;
  defaultBranchNameGTE?: InputMaybe<Scalars['String']>;
  defaultBranchNameHasPrefix?: InputMaybe<Scalars['String']>;
  defaultBranchNameHasSuffix?: InputMaybe<Scalars['String']>;
  defaultBranchNameIn?: InputMaybe<Array<Scalars['String']>>;
  defaultBranchNameLT?: InputMaybe<Scalars['String']>;
  defaultBranchNameLTE?: InputMaybe<Scalars['String']>;
  defaultBranchNameNEQ?: InputMaybe<Scalars['String']>;
  defaultBranchNameNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']>;
  descriptionContains?: InputMaybe<Scalars['String']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']>;
  descriptionGT?: InputMaybe<Scalars['String']>;
  descriptionGTE?: InputMaybe<Scalars['String']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']>>;
  descriptionLT?: InputMaybe<Scalars['String']>;
  descriptionLTE?: InputMaybe<Scalars['String']>;
  descriptionNEQ?: InputMaybe<Scalars['String']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<ProjectWhereInput>;
  or?: InputMaybe<Array<ProjectWhereInput>>;
  /** projecturl field predicates */
  projecturl?: InputMaybe<Scalars['String']>;
  projecturlContains?: InputMaybe<Scalars['String']>;
  projecturlContainsFold?: InputMaybe<Scalars['String']>;
  projecturlEqualFold?: InputMaybe<Scalars['String']>;
  projecturlGT?: InputMaybe<Scalars['String']>;
  projecturlGTE?: InputMaybe<Scalars['String']>;
  projecturlHasPrefix?: InputMaybe<Scalars['String']>;
  projecturlHasSuffix?: InputMaybe<Scalars['String']>;
  projecturlIn?: InputMaybe<Array<Scalars['String']>>;
  projecturlLT?: InputMaybe<Scalars['String']>;
  projecturlLTE?: InputMaybe<Scalars['String']>;
  projecturlNEQ?: InputMaybe<Scalars['String']>;
  projecturlNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** repo_id field predicates */
  repoID?: InputMaybe<Scalars['String']>;
  repoIDContains?: InputMaybe<Scalars['String']>;
  repoIDContainsFold?: InputMaybe<Scalars['String']>;
  repoIDEqualFold?: InputMaybe<Scalars['String']>;
  repoIDGT?: InputMaybe<Scalars['String']>;
  repoIDGTE?: InputMaybe<Scalars['String']>;
  repoIDHasPrefix?: InputMaybe<Scalars['String']>;
  repoIDHasSuffix?: InputMaybe<Scalars['String']>;
  repoIDIn?: InputMaybe<Array<Scalars['String']>>;
  repoIDLT?: InputMaybe<Scalars['String']>;
  repoIDLTE?: InputMaybe<Scalars['String']>;
  repoIDNEQ?: InputMaybe<Scalars['String']>;
  repoIDNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type PublicKey = Node & {
  __typename?: 'PublicKey';
  /** The expiry time for the cached public key data */
  cacheExpiry?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
  /** The curve used for the key */
  curve?: Maybe<PublicKeyCurve>;
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  /** The actual public key in PEM or other relevant format */
  key: Scalars['String'];
  /** The length of the key in bits */
  keyLength: Scalars['Int'];
  kmsProviders?: Maybe<KmsProvider>;
  modifiedBy?: Maybe<User>;
  oidcProviders?: Maybe<OidcProvider>;
  pkProvider?: Maybe<PkProvider>;
  /** The Key ID associated with this public key */
  sha256Fingerprint?: Maybe<Scalars['String']>;
  tenant: Tenant;
  /** The type of the public key */
  type: PublicKeyType;
  updatedAt: Scalars['Time'];
};

/** PublicKeyCurve is enum for the field curve */
export enum PublicKeyCurve {
  None = 'None',
  P256 = 'P256',
  P384 = 'P384',
  P521 = 'P521',
}

/** Ordering options for PublicKey connections */
export type PublicKeyOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order PublicKeys. */
  field: PublicKeyOrderField;
};

/** Properties by which PublicKey connections can be ordered. */
export enum PublicKeyOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/** PublicKeyType is enum for the field type */
export enum PublicKeyType {
  Ecdsa = 'ecdsa',
  Ed25519 = 'ed25519',
  Rsa = 'rsa',
}

/**
 * PublicKeyWhereInput is used for filtering PublicKey objects.
 * Input was generated by ent.
 */
export type PublicKeyWhereInput = {
  and?: InputMaybe<Array<PublicKeyWhereInput>>;
  /** cache_expiry field predicates */
  cacheExpiry?: InputMaybe<Scalars['Time']>;
  cacheExpiryGT?: InputMaybe<Scalars['Time']>;
  cacheExpiryGTE?: InputMaybe<Scalars['Time']>;
  cacheExpiryIn?: InputMaybe<Array<Scalars['Time']>>;
  cacheExpiryIsNil?: InputMaybe<Scalars['Boolean']>;
  cacheExpiryLT?: InputMaybe<Scalars['Time']>;
  cacheExpiryLTE?: InputMaybe<Scalars['Time']>;
  cacheExpiryNEQ?: InputMaybe<Scalars['Time']>;
  cacheExpiryNotIn?: InputMaybe<Array<Scalars['Time']>>;
  cacheExpiryNotNil?: InputMaybe<Scalars['Boolean']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** curve field predicates */
  curve?: InputMaybe<PublicKeyCurve>;
  curveIn?: InputMaybe<Array<PublicKeyCurve>>;
  curveIsNil?: InputMaybe<Scalars['Boolean']>;
  curveNEQ?: InputMaybe<PublicKeyCurve>;
  curveNotIn?: InputMaybe<Array<PublicKeyCurve>>;
  curveNotNil?: InputMaybe<Scalars['Boolean']>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** kms_providers edge predicates */
  hasKmsProviders?: InputMaybe<Scalars['Boolean']>;
  hasKmsProvidersWith?: InputMaybe<Array<KmsProviderWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** oidc_providers edge predicates */
  hasOidcProviders?: InputMaybe<Scalars['Boolean']>;
  hasOidcProvidersWith?: InputMaybe<Array<OidcProviderWhereInput>>;
  /** pk_provider edge predicates */
  hasPkProvider?: InputMaybe<Scalars['Boolean']>;
  hasPkProviderWith?: InputMaybe<Array<PkProviderWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** key field predicates */
  key?: InputMaybe<Scalars['String']>;
  keyContains?: InputMaybe<Scalars['String']>;
  keyContainsFold?: InputMaybe<Scalars['String']>;
  keyEqualFold?: InputMaybe<Scalars['String']>;
  keyGT?: InputMaybe<Scalars['String']>;
  keyGTE?: InputMaybe<Scalars['String']>;
  keyHasPrefix?: InputMaybe<Scalars['String']>;
  keyHasSuffix?: InputMaybe<Scalars['String']>;
  keyIn?: InputMaybe<Array<Scalars['String']>>;
  keyLT?: InputMaybe<Scalars['String']>;
  keyLTE?: InputMaybe<Scalars['String']>;
  /** key_length field predicates */
  keyLength?: InputMaybe<Scalars['Int']>;
  keyLengthGT?: InputMaybe<Scalars['Int']>;
  keyLengthGTE?: InputMaybe<Scalars['Int']>;
  keyLengthIn?: InputMaybe<Array<Scalars['Int']>>;
  keyLengthLT?: InputMaybe<Scalars['Int']>;
  keyLengthLTE?: InputMaybe<Scalars['Int']>;
  keyLengthNEQ?: InputMaybe<Scalars['Int']>;
  keyLengthNotIn?: InputMaybe<Array<Scalars['Int']>>;
  keyNEQ?: InputMaybe<Scalars['String']>;
  keyNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<PublicKeyWhereInput>;
  or?: InputMaybe<Array<PublicKeyWhereInput>>;
  /** sha256_fingerprint field predicates */
  sha256Fingerprint?: InputMaybe<Scalars['String']>;
  sha256FingerprintContains?: InputMaybe<Scalars['String']>;
  sha256FingerprintContainsFold?: InputMaybe<Scalars['String']>;
  sha256FingerprintEqualFold?: InputMaybe<Scalars['String']>;
  sha256FingerprintGT?: InputMaybe<Scalars['String']>;
  sha256FingerprintGTE?: InputMaybe<Scalars['String']>;
  sha256FingerprintHasPrefix?: InputMaybe<Scalars['String']>;
  sha256FingerprintHasSuffix?: InputMaybe<Scalars['String']>;
  sha256FingerprintIn?: InputMaybe<Array<Scalars['String']>>;
  sha256FingerprintIsNil?: InputMaybe<Scalars['Boolean']>;
  sha256FingerprintLT?: InputMaybe<Scalars['String']>;
  sha256FingerprintLTE?: InputMaybe<Scalars['String']>;
  sha256FingerprintNEQ?: InputMaybe<Scalars['String']>;
  sha256FingerprintNotIn?: InputMaybe<Array<Scalars['String']>>;
  sha256FingerprintNotNil?: InputMaybe<Scalars['Boolean']>;
  /** type field predicates */
  type?: InputMaybe<PublicKeyType>;
  typeIn?: InputMaybe<Array<PublicKeyType>>;
  typeNEQ?: InputMaybe<PublicKeyType>;
  typeNotIn?: InputMaybe<Array<PublicKeyType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type Query = {
  __typename?: 'Query';
  attestationPolicies: AttestationPolicyConnection;
  attestations: AttestationConnection;
  branches: BranchConnection;
  codeCommits: CodeCommitConnection;
  complianceFrameworks: ComplianceFrameworkConnection;
  dsses: DsseConnection;
  filterSets: Array<FilterSet>;
  functionaryStores: FunctionaryStoreConnection;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  policyBindings: PolicyBindingConnection;
  policyDefinitions: PolicyDefinitionConnection;
  policyReleases: PolicyReleaseConnection;
  projects: Array<Project>;
  repos: RepoConnection;
  subjects: SubjectConnection;
  tenants: TenantConnection;
  users: UserConnection;
};

export type QueryAttestationPoliciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AttestationPolicyOrder>;
  where?: InputMaybe<AttestationPolicyWhereInput>;
};

export type QueryAttestationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AttestationOrder>;
  where?: InputMaybe<AttestationWhereInput>;
};

export type QueryBranchesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BranchOrder>;
  where?: InputMaybe<BranchWhereInput>;
};

export type QueryCodeCommitsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CodeCommitOrder>;
  where?: InputMaybe<CodeCommitWhereInput>;
};

export type QueryComplianceFrameworksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ComplianceFrameworkOrder>;
  where?: InputMaybe<ComplianceFrameworkWhereInput>;
};

export type QueryDssesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DsseWhereInput>;
};

export type QueryFunctionaryStoresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FunctionaryStoreOrder>;
  where?: InputMaybe<FunctionaryStoreWhereInput>;
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryPolicyBindingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PolicyBindingOrder>;
  where?: InputMaybe<PolicyBindingWhereInput>;
};

export type QueryPolicyDefinitionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PolicyDefinitionOrder>;
  where?: InputMaybe<PolicyDefinitionWhereInput>;
};

export type QueryPolicyReleasesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PolicyReleaseOrder>;
  where?: InputMaybe<PolicyReleaseWhereInput>;
};

export type QueryReposArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RepoOrder>;
  where?: InputMaybe<RepoWhereInput>;
};

export type QuerySubjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubjectWhereInput>;
};

export type QueryTenantsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TenantOrder>;
  where?: InputMaybe<TenantWhereInput>;
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type Repo = Node & {
  __typename?: 'Repo';
  branches?: Maybe<Array<Branch>>;
  complianceDirectives?: Maybe<Array<ComplianceDirective>>;
  createdAt: Scalars['Time'];
  defaultBranchName: Scalars['String'];
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  dsses?: Maybe<Array<Dsse>>;
  fromtimestamp: Scalars['String'];
  gitProviderRepoID: Scalars['String'];
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  name: Scalars['String'];
  owner: Scalars['String'];
  path: Scalars['String'];
  policyBindings?: Maybe<Array<PolicyBinding>>;
  tenant: Tenant;
  type: RepoType;
  updatedAt: Scalars['Time'];
  url: Scalars['String'];
  users?: Maybe<Array<User>>;
};

/** A connection to a list of items. */
export type RepoConnection = {
  __typename?: 'RepoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RepoEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RepoEdge = {
  __typename?: 'RepoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<Repo>;
};

/** Ordering options for Repo connections */
export type RepoOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Repos. */
  field: RepoOrderField;
};

/** Properties by which Repo connections can be ordered. */
export enum RepoOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
  Path = 'path',
}

/** RepoType is enum for the field type */
export enum RepoType {
  Github = 'GITHUB',
  Gitlab = 'GITLAB',
}

/**
 * RepoWhereInput is used for filtering Repo objects.
 * Input was generated by ent.
 */
export type RepoWhereInput = {
  and?: InputMaybe<Array<RepoWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** default_branch_name field predicates */
  defaultBranchName?: InputMaybe<Scalars['String']>;
  defaultBranchNameContains?: InputMaybe<Scalars['String']>;
  defaultBranchNameContainsFold?: InputMaybe<Scalars['String']>;
  defaultBranchNameEqualFold?: InputMaybe<Scalars['String']>;
  defaultBranchNameGT?: InputMaybe<Scalars['String']>;
  defaultBranchNameGTE?: InputMaybe<Scalars['String']>;
  defaultBranchNameHasPrefix?: InputMaybe<Scalars['String']>;
  defaultBranchNameHasSuffix?: InputMaybe<Scalars['String']>;
  defaultBranchNameIn?: InputMaybe<Array<Scalars['String']>>;
  defaultBranchNameLT?: InputMaybe<Scalars['String']>;
  defaultBranchNameLTE?: InputMaybe<Scalars['String']>;
  defaultBranchNameNEQ?: InputMaybe<Scalars['String']>;
  defaultBranchNameNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']>;
  descriptionContains?: InputMaybe<Scalars['String']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']>;
  descriptionGT?: InputMaybe<Scalars['String']>;
  descriptionGTE?: InputMaybe<Scalars['String']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']>>;
  descriptionLT?: InputMaybe<Scalars['String']>;
  descriptionLTE?: InputMaybe<Scalars['String']>;
  descriptionNEQ?: InputMaybe<Scalars['String']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** fromTimestamp field predicates */
  fromtimestamp?: InputMaybe<Scalars['String']>;
  fromtimestampContains?: InputMaybe<Scalars['String']>;
  fromtimestampContainsFold?: InputMaybe<Scalars['String']>;
  fromtimestampEqualFold?: InputMaybe<Scalars['String']>;
  fromtimestampGT?: InputMaybe<Scalars['String']>;
  fromtimestampGTE?: InputMaybe<Scalars['String']>;
  fromtimestampHasPrefix?: InputMaybe<Scalars['String']>;
  fromtimestampHasSuffix?: InputMaybe<Scalars['String']>;
  fromtimestampIn?: InputMaybe<Array<Scalars['String']>>;
  fromtimestampLT?: InputMaybe<Scalars['String']>;
  fromtimestampLTE?: InputMaybe<Scalars['String']>;
  fromtimestampNEQ?: InputMaybe<Scalars['String']>;
  fromtimestampNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** git_provider_repo_id field predicates */
  gitProviderRepoID?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDContains?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDContainsFold?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDEqualFold?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDGT?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDGTE?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDHasPrefix?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDHasSuffix?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDIn?: InputMaybe<Array<Scalars['String']>>;
  gitProviderRepoIDLT?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDLTE?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDNEQ?: InputMaybe<Scalars['String']>;
  gitProviderRepoIDNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** branches edge predicates */
  hasBranches?: InputMaybe<Scalars['Boolean']>;
  hasBranchesWith?: InputMaybe<Array<BranchWhereInput>>;
  /** compliance_directives edge predicates */
  hasComplianceDirectives?: InputMaybe<Scalars['Boolean']>;
  hasComplianceDirectivesWith?: InputMaybe<Array<ComplianceDirectiveWhereInput>>;
  /** dsses edge predicates */
  hasDsses?: InputMaybe<Scalars['Boolean']>;
  hasDssesWith?: InputMaybe<Array<DsseWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** policy_bindings edge predicates */
  hasPolicyBindings?: InputMaybe<Scalars['Boolean']>;
  hasPolicyBindingsWith?: InputMaybe<Array<PolicyBindingWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** users edge predicates */
  hasUsers?: InputMaybe<Scalars['Boolean']>;
  hasUsersWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<RepoWhereInput>;
  or?: InputMaybe<Array<RepoWhereInput>>;
  /** owner field predicates */
  owner?: InputMaybe<Scalars['String']>;
  ownerContains?: InputMaybe<Scalars['String']>;
  ownerContainsFold?: InputMaybe<Scalars['String']>;
  ownerEqualFold?: InputMaybe<Scalars['String']>;
  ownerGT?: InputMaybe<Scalars['String']>;
  ownerGTE?: InputMaybe<Scalars['String']>;
  ownerHasPrefix?: InputMaybe<Scalars['String']>;
  ownerHasSuffix?: InputMaybe<Scalars['String']>;
  ownerIn?: InputMaybe<Array<Scalars['String']>>;
  ownerLT?: InputMaybe<Scalars['String']>;
  ownerLTE?: InputMaybe<Scalars['String']>;
  ownerNEQ?: InputMaybe<Scalars['String']>;
  ownerNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** path field predicates */
  path?: InputMaybe<Scalars['String']>;
  pathContains?: InputMaybe<Scalars['String']>;
  pathContainsFold?: InputMaybe<Scalars['String']>;
  pathEqualFold?: InputMaybe<Scalars['String']>;
  pathGT?: InputMaybe<Scalars['String']>;
  pathGTE?: InputMaybe<Scalars['String']>;
  pathHasPrefix?: InputMaybe<Scalars['String']>;
  pathHasSuffix?: InputMaybe<Scalars['String']>;
  pathIn?: InputMaybe<Array<Scalars['String']>>;
  pathLT?: InputMaybe<Scalars['String']>;
  pathLTE?: InputMaybe<Scalars['String']>;
  pathNEQ?: InputMaybe<Scalars['String']>;
  pathNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** type field predicates */
  type?: InputMaybe<RepoType>;
  typeIn?: InputMaybe<Array<RepoType>>;
  typeNEQ?: InputMaybe<RepoType>;
  typeNotIn?: InputMaybe<Array<RepoType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** url field predicates */
  url?: InputMaybe<Scalars['String']>;
  urlContains?: InputMaybe<Scalars['String']>;
  urlContainsFold?: InputMaybe<Scalars['String']>;
  urlEqualFold?: InputMaybe<Scalars['String']>;
  urlGT?: InputMaybe<Scalars['String']>;
  urlGTE?: InputMaybe<Scalars['String']>;
  urlHasPrefix?: InputMaybe<Scalars['String']>;
  urlHasSuffix?: InputMaybe<Scalars['String']>;
  urlIn?: InputMaybe<Array<Scalars['String']>>;
  urlLT?: InputMaybe<Scalars['String']>;
  urlLTE?: InputMaybe<Scalars['String']>;
  urlNEQ?: InputMaybe<Scalars['String']>;
  urlNotIn?: InputMaybe<Array<Scalars['String']>>;
};

export type Signature = Node & {
  __typename?: 'Signature';
  dsse?: Maybe<Dsse>;
  id: Scalars['ID'];
  keyID: Scalars['String'];
  signature: Scalars['String'];
  timestamps?: Maybe<Array<Timestamp>>;
};

/**
 * SignatureWhereInput is used for filtering Signature objects.
 * Input was generated by ent.
 */
export type SignatureWhereInput = {
  and?: InputMaybe<Array<SignatureWhereInput>>;
  /** dsse edge predicates */
  hasDsse?: InputMaybe<Scalars['Boolean']>;
  hasDsseWith?: InputMaybe<Array<DsseWhereInput>>;
  /** timestamps edge predicates */
  hasTimestamps?: InputMaybe<Scalars['Boolean']>;
  hasTimestampsWith?: InputMaybe<Array<TimestampWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** key_id field predicates */
  keyID?: InputMaybe<Scalars['String']>;
  keyIDContains?: InputMaybe<Scalars['String']>;
  keyIDContainsFold?: InputMaybe<Scalars['String']>;
  keyIDEqualFold?: InputMaybe<Scalars['String']>;
  keyIDGT?: InputMaybe<Scalars['String']>;
  keyIDGTE?: InputMaybe<Scalars['String']>;
  keyIDHasPrefix?: InputMaybe<Scalars['String']>;
  keyIDHasSuffix?: InputMaybe<Scalars['String']>;
  keyIDIn?: InputMaybe<Array<Scalars['String']>>;
  keyIDLT?: InputMaybe<Scalars['String']>;
  keyIDLTE?: InputMaybe<Scalars['String']>;
  keyIDNEQ?: InputMaybe<Scalars['String']>;
  keyIDNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<SignatureWhereInput>;
  or?: InputMaybe<Array<SignatureWhereInput>>;
  /** signature field predicates */
  signature?: InputMaybe<Scalars['String']>;
  signatureContains?: InputMaybe<Scalars['String']>;
  signatureContainsFold?: InputMaybe<Scalars['String']>;
  signatureEqualFold?: InputMaybe<Scalars['String']>;
  signatureGT?: InputMaybe<Scalars['String']>;
  signatureGTE?: InputMaybe<Scalars['String']>;
  signatureHasPrefix?: InputMaybe<Scalars['String']>;
  signatureHasSuffix?: InputMaybe<Scalars['String']>;
  signatureIn?: InputMaybe<Array<Scalars['String']>>;
  signatureLT?: InputMaybe<Scalars['String']>;
  signatureLTE?: InputMaybe<Scalars['String']>;
  signatureNEQ?: InputMaybe<Scalars['String']>;
  signatureNotIn?: InputMaybe<Array<Scalars['String']>>;
};

export type Statement = Node & {
  __typename?: 'Statement';
  attestationCollections?: Maybe<AttestationCollection>;
  dsse?: Maybe<Array<Dsse>>;
  id: Scalars['ID'];
  policy?: Maybe<AttestationPolicy>;
  predicate: Scalars['String'];
  subjects: SubjectConnection;
};

export type StatementSubjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubjectWhereInput>;
};

/**
 * StatementWhereInput is used for filtering Statement objects.
 * Input was generated by ent.
 */
export type StatementWhereInput = {
  and?: InputMaybe<Array<StatementWhereInput>>;
  /** attestation_collections edge predicates */
  hasAttestationCollections?: InputMaybe<Scalars['Boolean']>;
  hasAttestationCollectionsWith?: InputMaybe<Array<AttestationCollectionWhereInput>>;
  /** dsse edge predicates */
  hasDsse?: InputMaybe<Scalars['Boolean']>;
  hasDsseWith?: InputMaybe<Array<DsseWhereInput>>;
  /** policy edge predicates */
  hasPolicy?: InputMaybe<Scalars['Boolean']>;
  hasPolicyWith?: InputMaybe<Array<AttestationPolicyWhereInput>>;
  /** subjects edge predicates */
  hasSubjects?: InputMaybe<Scalars['Boolean']>;
  hasSubjectsWith?: InputMaybe<Array<SubjectWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  not?: InputMaybe<StatementWhereInput>;
  or?: InputMaybe<Array<StatementWhereInput>>;
  /** predicate field predicates */
  predicate?: InputMaybe<Scalars['String']>;
  predicateContains?: InputMaybe<Scalars['String']>;
  predicateContainsFold?: InputMaybe<Scalars['String']>;
  predicateEqualFold?: InputMaybe<Scalars['String']>;
  predicateGT?: InputMaybe<Scalars['String']>;
  predicateGTE?: InputMaybe<Scalars['String']>;
  predicateHasPrefix?: InputMaybe<Scalars['String']>;
  predicateHasSuffix?: InputMaybe<Scalars['String']>;
  predicateIn?: InputMaybe<Array<Scalars['String']>>;
  predicateLT?: InputMaybe<Scalars['String']>;
  predicateLTE?: InputMaybe<Scalars['String']>;
  predicateNEQ?: InputMaybe<Scalars['String']>;
  predicateNotIn?: InputMaybe<Array<Scalars['String']>>;
};

export type Subject = Node & {
  __typename?: 'Subject';
  id: Scalars['ID'];
  name: Scalars['String'];
  statement?: Maybe<Statement>;
  subjectDigests?: Maybe<Array<SubjectDigest>>;
};

/** A connection to a list of items. */
export type SubjectConnection = {
  __typename?: 'SubjectConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubjectEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type SubjectDigest = Node & {
  __typename?: 'SubjectDigest';
  algorithm: Scalars['String'];
  id: Scalars['ID'];
  subject?: Maybe<Subject>;
  value: Scalars['String'];
};

/**
 * SubjectDigestWhereInput is used for filtering SubjectDigest objects.
 * Input was generated by ent.
 */
export type SubjectDigestWhereInput = {
  /** algorithm field predicates */
  algorithm?: InputMaybe<Scalars['String']>;
  algorithmContains?: InputMaybe<Scalars['String']>;
  algorithmContainsFold?: InputMaybe<Scalars['String']>;
  algorithmEqualFold?: InputMaybe<Scalars['String']>;
  algorithmGT?: InputMaybe<Scalars['String']>;
  algorithmGTE?: InputMaybe<Scalars['String']>;
  algorithmHasPrefix?: InputMaybe<Scalars['String']>;
  algorithmHasSuffix?: InputMaybe<Scalars['String']>;
  algorithmIn?: InputMaybe<Array<Scalars['String']>>;
  algorithmLT?: InputMaybe<Scalars['String']>;
  algorithmLTE?: InputMaybe<Scalars['String']>;
  algorithmNEQ?: InputMaybe<Scalars['String']>;
  algorithmNotIn?: InputMaybe<Array<Scalars['String']>>;
  and?: InputMaybe<Array<SubjectDigestWhereInput>>;
  /** subject edge predicates */
  hasSubject?: InputMaybe<Scalars['Boolean']>;
  hasSubjectWith?: InputMaybe<Array<SubjectWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  not?: InputMaybe<SubjectDigestWhereInput>;
  or?: InputMaybe<Array<SubjectDigestWhereInput>>;
  /** value field predicates */
  value?: InputMaybe<Scalars['String']>;
  valueContains?: InputMaybe<Scalars['String']>;
  valueContainsFold?: InputMaybe<Scalars['String']>;
  valueEqualFold?: InputMaybe<Scalars['String']>;
  valueGT?: InputMaybe<Scalars['String']>;
  valueGTE?: InputMaybe<Scalars['String']>;
  valueHasPrefix?: InputMaybe<Scalars['String']>;
  valueHasSuffix?: InputMaybe<Scalars['String']>;
  valueIn?: InputMaybe<Array<Scalars['String']>>;
  valueLT?: InputMaybe<Scalars['String']>;
  valueLTE?: InputMaybe<Scalars['String']>;
  valueNEQ?: InputMaybe<Scalars['String']>;
  valueNotIn?: InputMaybe<Array<Scalars['String']>>;
};

/** An edge in a connection. */
export type SubjectEdge = {
  __typename?: 'SubjectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<Subject>;
};

/**
 * SubjectWhereInput is used for filtering Subject objects.
 * Input was generated by ent.
 */
export type SubjectWhereInput = {
  and?: InputMaybe<Array<SubjectWhereInput>>;
  /** statement edge predicates */
  hasStatement?: InputMaybe<Scalars['Boolean']>;
  hasStatementWith?: InputMaybe<Array<StatementWhereInput>>;
  /** subject_digests edge predicates */
  hasSubjectDigests?: InputMaybe<Scalars['Boolean']>;
  hasSubjectDigestsWith?: InputMaybe<Array<SubjectDigestWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<SubjectWhereInput>;
  or?: InputMaybe<Array<SubjectWhereInput>>;
};

export type TsaProvider = Node & {
  __typename?: 'TSAProvider';
  /** Cache expiry time for the provider data */
  cacheExp?: Maybe<Scalars['Time']>;
  chain?: Maybe<Array<Chain>>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  functionaryStore?: Maybe<FunctionaryStore>;
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  tenant: Tenant;
  /** TSA URL */
  tsaURL: Scalars['String'];
  updatedAt: Scalars['Time'];
};

/** Ordering options for TSAProvider connections */
export type TsaProviderOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order TSAProviders. */
  field: TsaProviderOrderField;
};

/** Properties by which TSAProvider connections can be ordered. */
export enum TsaProviderOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/**
 * TSAProviderWhereInput is used for filtering TSAProvider objects.
 * Input was generated by ent.
 */
export type TsaProviderWhereInput = {
  and?: InputMaybe<Array<TsaProviderWhereInput>>;
  /** cache_exp field predicates */
  cacheExp?: InputMaybe<Scalars['Time']>;
  cacheExpGT?: InputMaybe<Scalars['Time']>;
  cacheExpGTE?: InputMaybe<Scalars['Time']>;
  cacheExpIn?: InputMaybe<Array<Scalars['Time']>>;
  cacheExpIsNil?: InputMaybe<Scalars['Boolean']>;
  cacheExpLT?: InputMaybe<Scalars['Time']>;
  cacheExpLTE?: InputMaybe<Scalars['Time']>;
  cacheExpNEQ?: InputMaybe<Scalars['Time']>;
  cacheExpNotIn?: InputMaybe<Array<Scalars['Time']>>;
  cacheExpNotNil?: InputMaybe<Scalars['Boolean']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** chain edge predicates */
  hasChain?: InputMaybe<Scalars['Boolean']>;
  hasChainWith?: InputMaybe<Array<ChainWhereInput>>;
  /** functionary_store edge predicates */
  hasFunctionaryStore?: InputMaybe<Scalars['Boolean']>;
  hasFunctionaryStoreWith?: InputMaybe<Array<FunctionaryStoreWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  not?: InputMaybe<TsaProviderWhereInput>;
  or?: InputMaybe<Array<TsaProviderWhereInput>>;
  /** tsa_url field predicates */
  tsaURL?: InputMaybe<Scalars['String']>;
  tsaURLContains?: InputMaybe<Scalars['String']>;
  tsaURLContainsFold?: InputMaybe<Scalars['String']>;
  tsaURLEqualFold?: InputMaybe<Scalars['String']>;
  tsaURLGT?: InputMaybe<Scalars['String']>;
  tsaURLGTE?: InputMaybe<Scalars['String']>;
  tsaURLHasPrefix?: InputMaybe<Scalars['String']>;
  tsaURLHasSuffix?: InputMaybe<Scalars['String']>;
  tsaURLIn?: InputMaybe<Array<Scalars['String']>>;
  tsaURLLT?: InputMaybe<Scalars['String']>;
  tsaURLLTE?: InputMaybe<Scalars['String']>;
  tsaURLNEQ?: InputMaybe<Scalars['String']>;
  tsaURLNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type Tenant = Node & {
  __typename?: 'Tenant';
  children?: Maybe<Array<Tenant>>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  modifiedBy?: Maybe<User>;
  name: Scalars['String'];
  parent?: Maybe<Tenant>;
  type: TenantType;
  updatedAt: Scalars['Time'];
  users?: Maybe<Array<User>>;
};

/** A connection to a list of items. */
export type TenantConnection = {
  __typename?: 'TenantConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TenantEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TenantEdge = {
  __typename?: 'TenantEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<Tenant>;
};

/** Ordering options for Tenant connections */
export type TenantOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Tenants. */
  field: TenantOrderField;
};

/** Properties by which Tenant connections can be ordered. */
export enum TenantOrderField {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  Id = 'ID',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT',
}

/** TenantType is enum for the field type */
export enum TenantType {
  Org = 'ORG',
  Root = 'ROOT',
  Team = 'TEAM',
}

/**
 * TenantWhereInput is used for filtering Tenant objects.
 * Input was generated by ent.
 */
export type TenantWhereInput = {
  and?: InputMaybe<Array<TenantWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** deleted_at field predicates */
  deletedAt?: InputMaybe<Scalars['Time']>;
  deletedAtGT?: InputMaybe<Scalars['Time']>;
  deletedAtGTE?: InputMaybe<Scalars['Time']>;
  deletedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtIsNil?: InputMaybe<Scalars['Boolean']>;
  deletedAtLT?: InputMaybe<Scalars['Time']>;
  deletedAtLTE?: InputMaybe<Scalars['Time']>;
  deletedAtNEQ?: InputMaybe<Scalars['Time']>;
  deletedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  deletedAtNotNil?: InputMaybe<Scalars['Boolean']>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']>;
  descriptionContains?: InputMaybe<Scalars['String']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']>;
  descriptionGT?: InputMaybe<Scalars['String']>;
  descriptionGTE?: InputMaybe<Scalars['String']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']>>;
  descriptionLT?: InputMaybe<Scalars['String']>;
  descriptionLTE?: InputMaybe<Scalars['String']>;
  descriptionNEQ?: InputMaybe<Scalars['String']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** children edge predicates */
  hasChildren?: InputMaybe<Scalars['Boolean']>;
  hasChildrenWith?: InputMaybe<Array<TenantWhereInput>>;
  /** modified_by edge predicates */
  hasModifiedBy?: InputMaybe<Scalars['Boolean']>;
  hasModifiedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** parent edge predicates */
  hasParent?: InputMaybe<Scalars['Boolean']>;
  hasParentWith?: InputMaybe<Array<TenantWhereInput>>;
  /** users edge predicates */
  hasUsers?: InputMaybe<Scalars['Boolean']>;
  hasUsersWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<TenantWhereInput>;
  or?: InputMaybe<Array<TenantWhereInput>>;
  /** type field predicates */
  type?: InputMaybe<TenantType>;
  typeIn?: InputMaybe<Array<TenantType>>;
  typeNEQ?: InputMaybe<TenantType>;
  typeNotIn?: InputMaybe<Array<TenantType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type Timestamp = Node & {
  __typename?: 'Timestamp';
  id: Scalars['ID'];
  signature?: Maybe<Signature>;
  timestamp: Scalars['Time'];
  type: Scalars['String'];
};

/**
 * TimestampWhereInput is used for filtering Timestamp objects.
 * Input was generated by ent.
 */
export type TimestampWhereInput = {
  and?: InputMaybe<Array<TimestampWhereInput>>;
  /** signature edge predicates */
  hasSignature?: InputMaybe<Scalars['Boolean']>;
  hasSignatureWith?: InputMaybe<Array<SignatureWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  not?: InputMaybe<TimestampWhereInput>;
  or?: InputMaybe<Array<TimestampWhereInput>>;
  /** timestamp field predicates */
  timestamp?: InputMaybe<Scalars['Time']>;
  timestampGT?: InputMaybe<Scalars['Time']>;
  timestampGTE?: InputMaybe<Scalars['Time']>;
  timestampIn?: InputMaybe<Array<Scalars['Time']>>;
  timestampLT?: InputMaybe<Scalars['Time']>;
  timestampLTE?: InputMaybe<Scalars['Time']>;
  timestampNEQ?: InputMaybe<Scalars['Time']>;
  timestampNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** type field predicates */
  type?: InputMaybe<Scalars['String']>;
  typeContains?: InputMaybe<Scalars['String']>;
  typeContainsFold?: InputMaybe<Scalars['String']>;
  typeEqualFold?: InputMaybe<Scalars['String']>;
  typeGT?: InputMaybe<Scalars['String']>;
  typeGTE?: InputMaybe<Scalars['String']>;
  typeHasPrefix?: InputMaybe<Scalars['String']>;
  typeHasSuffix?: InputMaybe<Scalars['String']>;
  typeIn?: InputMaybe<Array<Scalars['String']>>;
  typeLT?: InputMaybe<Scalars['String']>;
  typeLTE?: InputMaybe<Scalars['String']>;
  typeNEQ?: InputMaybe<Scalars['String']>;
  typeNotIn?: InputMaybe<Array<Scalars['String']>>;
};

/**
 * UpdateCAProviderInput is used for update CAProvider object.
 * Input was generated by ent.
 */
export type UpdateCaProviderInput = {
  addCertConstraintIDs?: InputMaybe<Array<Scalars['ID']>>;
  addChainIDs?: InputMaybe<Array<Scalars['ID']>>;
  addFunctionaryStoreIDs?: InputMaybe<Array<Scalars['ID']>>;
  /** CA URL */
  caURL?: InputMaybe<Scalars['String']>;
  /** Cache expiry time for the provider data */
  cacheExp?: InputMaybe<Scalars['Time']>;
  clearCaURL?: InputMaybe<Scalars['Boolean']>;
  clearCacheExp?: InputMaybe<Scalars['Boolean']>;
  clearCertConstraints?: InputMaybe<Scalars['Boolean']>;
  clearChains?: InputMaybe<Scalars['Boolean']>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']>;
  clearFunctionaryStore?: InputMaybe<Scalars['Boolean']>;
  clearModifiedBy?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  /** Name of the CA provider */
  name?: InputMaybe<Scalars['String']>;
  removeCertConstraintIDs?: InputMaybe<Array<Scalars['ID']>>;
  removeChainIDs?: InputMaybe<Array<Scalars['ID']>>;
  removeFunctionaryStoreIDs?: InputMaybe<Array<Scalars['ID']>>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * UpdateFilterSetInput is used for update FilterSet object.
 * Input was generated by ent.
 */
export type UpdateFilterSetInput = {
  addFilterIDs?: InputMaybe<Array<Scalars['ID']>>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']>;
  clearFilters?: InputMaybe<Scalars['Boolean']>;
  clearModifiedBy?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  description?: InputMaybe<Scalars['String']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  removeFilterIDs?: InputMaybe<Array<Scalars['ID']>>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * UpdateFunctionaryStoreInput is used for update FunctionaryStore object.
 * Input was generated by ent.
 */
export type UpdateFunctionaryStoreInput = {
  addCaProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  addKmsProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  addOidcProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  addPkProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  addTsaProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  clearCaProviders?: InputMaybe<Scalars['Boolean']>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']>;
  clearDesc?: InputMaybe<Scalars['Boolean']>;
  clearKmsProviders?: InputMaybe<Scalars['Boolean']>;
  clearModifiedBy?: InputMaybe<Scalars['Boolean']>;
  clearOidcProviders?: InputMaybe<Scalars['Boolean']>;
  clearPkProviders?: InputMaybe<Scalars['Boolean']>;
  clearTsaProviders?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  /** Description of the Functionary Store */
  desc?: InputMaybe<Scalars['String']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  /** Name of the Functionary Store */
  name?: InputMaybe<Scalars['String']>;
  removeCaProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  removeKmsProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  removeOidcProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  removePkProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  removeTsaProviderIDs?: InputMaybe<Array<Scalars['ID']>>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * UpdateKMSProviderInput is used for update KMSProvider object.
 * Input was generated by ent.
 */
export type UpdateKmsProviderInput = {
  addPublicKeyIDs?: InputMaybe<Array<Scalars['ID']>>;
  /** Cache expiry time for the provider data */
  cacheExp?: InputMaybe<Scalars['Time']>;
  clearCacheExp?: InputMaybe<Scalars['Boolean']>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']>;
  clearFunctionaryStore?: InputMaybe<Scalars['Boolean']>;
  clearModifiedBy?: InputMaybe<Scalars['Boolean']>;
  clearPublicKeys?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  functionaryStoreID?: InputMaybe<Scalars['ID']>;
  /** KMS URL */
  kmsURL?: InputMaybe<Scalars['String']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  removePublicKeyIDs?: InputMaybe<Array<Scalars['ID']>>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * UpdateOIDCProviderInput is used for update OIDCProvider object.
 * Input was generated by ent.
 */
export type UpdateOidcProviderInput = {
  addOidcConstraintIDs?: InputMaybe<Array<Scalars['ID']>>;
  addPublicKeyIDs?: InputMaybe<Array<Scalars['ID']>>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']>;
  clearFunctionaryStore?: InputMaybe<Scalars['Boolean']>;
  clearModifiedBy?: InputMaybe<Scalars['Boolean']>;
  clearOidcConstraints?: InputMaybe<Scalars['Boolean']>;
  clearPublicKeys?: InputMaybe<Scalars['Boolean']>;
  /** Client ID for the OIDC provider */
  clientID?: InputMaybe<Scalars['String']>;
  /** Client Secret for the OIDC provider */
  clientSecret?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  functionaryStoreID?: InputMaybe<Scalars['ID']>;
  /** Issuer URL for the OIDC provider */
  issuer?: InputMaybe<Scalars['String']>;
  /** JWKS URI for the OIDC provider */
  jwksURI?: InputMaybe<Scalars['String']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  /** Name of the OIDC provider */
  name?: InputMaybe<Scalars['String']>;
  removeOidcConstraintIDs?: InputMaybe<Array<Scalars['ID']>>;
  removePublicKeyIDs?: InputMaybe<Array<Scalars['ID']>>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * UpdatePKProviderInput is used for update PKProvider object.
 * Input was generated by ent.
 */
export type UpdatePkProviderInput = {
  addFunctionaryStoreIDs?: InputMaybe<Array<Scalars['ID']>>;
  addPublicKeyIDs?: InputMaybe<Array<Scalars['ID']>>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']>;
  clearDesc?: InputMaybe<Scalars['Boolean']>;
  clearFunctionaryStore?: InputMaybe<Scalars['Boolean']>;
  clearModifiedBy?: InputMaybe<Scalars['Boolean']>;
  clearPublicKeys?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  /** Description of the PK provider */
  desc?: InputMaybe<Scalars['String']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  /** Name of the PK provider */
  name?: InputMaybe<Scalars['String']>;
  removeFunctionaryStoreIDs?: InputMaybe<Array<Scalars['ID']>>;
  removePublicKeyIDs?: InputMaybe<Array<Scalars['ID']>>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * UpdatePolicyBindingInput is used for update PolicyBinding object.
 * Input was generated by ent.
 */
export type UpdatePolicyBindingInput = {
  addRepoIDs?: InputMaybe<Array<Scalars['ID']>>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']>;
  clearModifiedBy?: InputMaybe<Scalars['Boolean']>;
  clearPolicyDefinition?: InputMaybe<Scalars['Boolean']>;
  clearPolicyRelease?: InputMaybe<Scalars['Boolean']>;
  clearRepos?: InputMaybe<Scalars['Boolean']>;
  clearUser?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  policyDefinitionID?: InputMaybe<Scalars['ID']>;
  policyReleaseID?: InputMaybe<Scalars['ID']>;
  removeRepoIDs?: InputMaybe<Array<Scalars['ID']>>;
  updatedAt?: InputMaybe<Scalars['Time']>;
  userID?: InputMaybe<Scalars['ID']>;
};

/**
 * UpdatePolicyDefinitionInput is used for update PolicyDefinition object.
 * Input was generated by ent.
 */
export type UpdatePolicyDefinitionInput = {
  addDssIDs?: InputMaybe<Array<Scalars['ID']>>;
  addPolicyReleaseIDs?: InputMaybe<Array<Scalars['ID']>>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']>;
  clearDsses?: InputMaybe<Scalars['Boolean']>;
  clearModifiedBy?: InputMaybe<Scalars['Boolean']>;
  clearPolicyRelease?: InputMaybe<Scalars['Boolean']>;
  clearUser?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  description?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  removeDssIDs?: InputMaybe<Array<Scalars['ID']>>;
  removePolicyReleaseIDs?: InputMaybe<Array<Scalars['ID']>>;
  updatedAt?: InputMaybe<Scalars['Time']>;
  userID?: InputMaybe<Scalars['ID']>;
};

/**
 * UpdatePolicyReleaseInput is used for update PolicyRelease object.
 * Input was generated by ent.
 */
export type UpdatePolicyReleaseInput = {
  addPolicyBindingIDs?: InputMaybe<Array<Scalars['ID']>>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']>;
  clearDsse?: InputMaybe<Scalars['Boolean']>;
  clearModifiedBy?: InputMaybe<Scalars['Boolean']>;
  clearPolicyBinding?: InputMaybe<Scalars['Boolean']>;
  clearPolicyDefinition?: InputMaybe<Scalars['Boolean']>;
  clearUser?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  dsseID?: InputMaybe<Scalars['ID']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  policyDefinitionID?: InputMaybe<Scalars['ID']>;
  removePolicyBindingIDs?: InputMaybe<Array<Scalars['ID']>>;
  tag?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Time']>;
  userID?: InputMaybe<Scalars['ID']>;
};

/**
 * UpdateRepoInput is used for update Repo object.
 * Input was generated by ent.
 */
export type UpdateRepoInput = {
  addBranchIDs?: InputMaybe<Array<Scalars['ID']>>;
  addComplianceDirectiveIDs?: InputMaybe<Array<Scalars['ID']>>;
  addDssIDs?: InputMaybe<Array<Scalars['ID']>>;
  addPolicyBindingIDs?: InputMaybe<Array<Scalars['ID']>>;
  addUserIDs?: InputMaybe<Array<Scalars['ID']>>;
  clearBranches?: InputMaybe<Scalars['Boolean']>;
  clearComplianceDirectives?: InputMaybe<Scalars['Boolean']>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']>;
  clearDsses?: InputMaybe<Scalars['Boolean']>;
  clearModifiedBy?: InputMaybe<Scalars['Boolean']>;
  clearPolicyBindings?: InputMaybe<Scalars['Boolean']>;
  clearUsers?: InputMaybe<Scalars['Boolean']>;
  defaultBranchName?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  description?: InputMaybe<Scalars['String']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  owner?: InputMaybe<Scalars['String']>;
  removeBranchIDs?: InputMaybe<Array<Scalars['ID']>>;
  removeComplianceDirectiveIDs?: InputMaybe<Array<Scalars['ID']>>;
  removeDssIDs?: InputMaybe<Array<Scalars['ID']>>;
  removePolicyBindingIDs?: InputMaybe<Array<Scalars['ID']>>;
  removeUserIDs?: InputMaybe<Array<Scalars['ID']>>;
  updatedAt?: InputMaybe<Scalars['Time']>;
  url?: InputMaybe<Scalars['String']>;
};

/**
 * UpdateTSAProviderInput is used for update TSAProvider object.
 * Input was generated by ent.
 */
export type UpdateTsaProviderInput = {
  addChainIDs?: InputMaybe<Array<Scalars['ID']>>;
  /** Cache expiry time for the provider data */
  cacheExp?: InputMaybe<Scalars['Time']>;
  clearCacheExp?: InputMaybe<Scalars['Boolean']>;
  clearChain?: InputMaybe<Scalars['Boolean']>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']>;
  clearFunctionaryStore?: InputMaybe<Scalars['Boolean']>;
  clearModifiedBy?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  functionaryStoreID?: InputMaybe<Scalars['ID']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  removeChainIDs?: InputMaybe<Array<Scalars['ID']>>;
  /** TSA URL */
  tsaURL?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * UpdateTenantInput is used for update Tenant object.
 * Input was generated by ent.
 */
export type UpdateTenantInput = {
  addChildIDs?: InputMaybe<Array<Scalars['ID']>>;
  addUserIDs?: InputMaybe<Array<Scalars['ID']>>;
  clearChildren?: InputMaybe<Scalars['Boolean']>;
  clearDeletedAt?: InputMaybe<Scalars['Boolean']>;
  clearModifiedBy?: InputMaybe<Scalars['Boolean']>;
  clearParent?: InputMaybe<Scalars['Boolean']>;
  clearUsers?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['Time']>;
  description?: InputMaybe<Scalars['String']>;
  modifiedByID?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  parentID?: InputMaybe<Scalars['ID']>;
  removeChildIDs?: InputMaybe<Array<Scalars['ID']>>;
  removeUserIDs?: InputMaybe<Array<Scalars['ID']>>;
  type?: InputMaybe<TenantType>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

/**
 * UpdateUserInput is used for update User object.
 * Input was generated by ent.
 */
export type UpdateUserInput = {
  addPolicyBindingIDs?: InputMaybe<Array<Scalars['ID']>>;
  addPolicyDefinitionIDs?: InputMaybe<Array<Scalars['ID']>>;
  addPolicyReleaseIDs?: InputMaybe<Array<Scalars['ID']>>;
  addTenantIDs?: InputMaybe<Array<Scalars['ID']>>;
  clearPolicyBindings?: InputMaybe<Scalars['Boolean']>;
  clearPolicyDefinitions?: InputMaybe<Scalars['Boolean']>;
  clearPolicyReleases?: InputMaybe<Scalars['Boolean']>;
  clearTenant?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Time']>;
  email?: InputMaybe<Scalars['String']>;
  identityID?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  removePolicyBindingIDs?: InputMaybe<Array<Scalars['ID']>>;
  removePolicyDefinitionIDs?: InputMaybe<Array<Scalars['ID']>>;
  removePolicyReleaseIDs?: InputMaybe<Array<Scalars['ID']>>;
  removeTenantIDs?: InputMaybe<Array<Scalars['ID']>>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

export type User = Node & {
  __typename?: 'User';
  createdAt: Scalars['Time'];
  email: Scalars['String'];
  id: Scalars['ID'];
  identityID: Scalars['String'];
  name: Scalars['String'];
  policyBindings?: Maybe<Array<PolicyBinding>>;
  policyDefinitions?: Maybe<Array<PolicyDefinition>>;
  policyReleases?: Maybe<Array<PolicyRelease>>;
  tenant?: Maybe<Array<Tenant>>;
  title: Scalars['String'];
  updatedAt: Scalars['Time'];
};

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

/**
 * UserWhereInput is used for filtering User objects.
 * Input was generated by ent.
 */
export type UserWhereInput = {
  and?: InputMaybe<Array<UserWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']>;
  createdAtGT?: InputMaybe<Scalars['Time']>;
  createdAtGTE?: InputMaybe<Scalars['Time']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']>>;
  createdAtLT?: InputMaybe<Scalars['Time']>;
  createdAtLTE?: InputMaybe<Scalars['Time']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** email field predicates */
  email?: InputMaybe<Scalars['String']>;
  emailContains?: InputMaybe<Scalars['String']>;
  emailContainsFold?: InputMaybe<Scalars['String']>;
  emailEqualFold?: InputMaybe<Scalars['String']>;
  emailGT?: InputMaybe<Scalars['String']>;
  emailGTE?: InputMaybe<Scalars['String']>;
  emailHasPrefix?: InputMaybe<Scalars['String']>;
  emailHasSuffix?: InputMaybe<Scalars['String']>;
  emailIn?: InputMaybe<Array<Scalars['String']>>;
  emailLT?: InputMaybe<Scalars['String']>;
  emailLTE?: InputMaybe<Scalars['String']>;
  emailNEQ?: InputMaybe<Scalars['String']>;
  emailNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** policy_bindings edge predicates */
  hasPolicyBindings?: InputMaybe<Scalars['Boolean']>;
  hasPolicyBindingsWith?: InputMaybe<Array<PolicyBindingWhereInput>>;
  /** policy_definitions edge predicates */
  hasPolicyDefinitions?: InputMaybe<Scalars['Boolean']>;
  hasPolicyDefinitionsWith?: InputMaybe<Array<PolicyDefinitionWhereInput>>;
  /** policy_releases edge predicates */
  hasPolicyReleases?: InputMaybe<Scalars['Boolean']>;
  hasPolicyReleasesWith?: InputMaybe<Array<PolicyReleaseWhereInput>>;
  /** tenant edge predicates */
  hasTenant?: InputMaybe<Scalars['Boolean']>;
  hasTenantWith?: InputMaybe<Array<TenantWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** identity_id field predicates */
  identityID?: InputMaybe<Scalars['String']>;
  identityIDContains?: InputMaybe<Scalars['String']>;
  identityIDContainsFold?: InputMaybe<Scalars['String']>;
  identityIDEqualFold?: InputMaybe<Scalars['String']>;
  identityIDGT?: InputMaybe<Scalars['String']>;
  identityIDGTE?: InputMaybe<Scalars['String']>;
  identityIDHasPrefix?: InputMaybe<Scalars['String']>;
  identityIDHasSuffix?: InputMaybe<Scalars['String']>;
  identityIDIn?: InputMaybe<Array<Scalars['String']>>;
  identityIDLT?: InputMaybe<Scalars['String']>;
  identityIDLTE?: InputMaybe<Scalars['String']>;
  identityIDNEQ?: InputMaybe<Scalars['String']>;
  identityIDNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameContainsFold?: InputMaybe<Scalars['String']>;
  nameEqualFold?: InputMaybe<Scalars['String']>;
  nameGT?: InputMaybe<Scalars['String']>;
  nameGTE?: InputMaybe<Scalars['String']>;
  nameHasPrefix?: InputMaybe<Scalars['String']>;
  nameHasSuffix?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<Scalars['String']>>;
  nameLT?: InputMaybe<Scalars['String']>;
  nameLTE?: InputMaybe<Scalars['String']>;
  nameNEQ?: InputMaybe<Scalars['String']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']>>;
  not?: InputMaybe<UserWhereInput>;
  or?: InputMaybe<Array<UserWhereInput>>;
  /** title field predicates */
  title?: InputMaybe<Scalars['String']>;
  titleContains?: InputMaybe<Scalars['String']>;
  titleContainsFold?: InputMaybe<Scalars['String']>;
  titleEqualFold?: InputMaybe<Scalars['String']>;
  titleGT?: InputMaybe<Scalars['String']>;
  titleGTE?: InputMaybe<Scalars['String']>;
  titleHasPrefix?: InputMaybe<Scalars['String']>;
  titleHasSuffix?: InputMaybe<Scalars['String']>;
  titleIn?: InputMaybe<Array<Scalars['String']>>;
  titleLT?: InputMaybe<Scalars['String']>;
  titleLTE?: InputMaybe<Scalars['String']>;
  titleNEQ?: InputMaybe<Scalars['String']>;
  titleNotIn?: InputMaybe<Array<Scalars['String']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']>;
  updatedAtGT?: InputMaybe<Scalars['Time']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']>>;
};

export type VulnSummary = Node & {
  __typename?: 'VulnSummary';
  critical: Scalars['Int'];
  dsse?: Maybe<Dsse>;
  high: Scalars['Int'];
  id: Scalars['ID'];
  low: Scalars['Int'];
  medium: Scalars['Int'];
  timestamp: Scalars['Time'];
  total: Scalars['Int'];
};

/**
 * VulnSummaryWhereInput is used for filtering VulnSummary objects.
 * Input was generated by ent.
 */
export type VulnSummaryWhereInput = {
  and?: InputMaybe<Array<VulnSummaryWhereInput>>;
  /** critical field predicates */
  critical?: InputMaybe<Scalars['Int']>;
  criticalGT?: InputMaybe<Scalars['Int']>;
  criticalGTE?: InputMaybe<Scalars['Int']>;
  criticalIn?: InputMaybe<Array<Scalars['Int']>>;
  criticalLT?: InputMaybe<Scalars['Int']>;
  criticalLTE?: InputMaybe<Scalars['Int']>;
  criticalNEQ?: InputMaybe<Scalars['Int']>;
  criticalNotIn?: InputMaybe<Array<Scalars['Int']>>;
  /** dsse edge predicates */
  hasDsse?: InputMaybe<Scalars['Boolean']>;
  hasDsseWith?: InputMaybe<Array<DsseWhereInput>>;
  /** high field predicates */
  high?: InputMaybe<Scalars['Int']>;
  highGT?: InputMaybe<Scalars['Int']>;
  highGTE?: InputMaybe<Scalars['Int']>;
  highIn?: InputMaybe<Array<Scalars['Int']>>;
  highLT?: InputMaybe<Scalars['Int']>;
  highLTE?: InputMaybe<Scalars['Int']>;
  highNEQ?: InputMaybe<Scalars['Int']>;
  highNotIn?: InputMaybe<Array<Scalars['Int']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']>;
  idGT?: InputMaybe<Scalars['ID']>;
  idGTE?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idLT?: InputMaybe<Scalars['ID']>;
  idLTE?: InputMaybe<Scalars['ID']>;
  idNEQ?: InputMaybe<Scalars['ID']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']>>;
  /** low field predicates */
  low?: InputMaybe<Scalars['Int']>;
  lowGT?: InputMaybe<Scalars['Int']>;
  lowGTE?: InputMaybe<Scalars['Int']>;
  lowIn?: InputMaybe<Array<Scalars['Int']>>;
  lowLT?: InputMaybe<Scalars['Int']>;
  lowLTE?: InputMaybe<Scalars['Int']>;
  lowNEQ?: InputMaybe<Scalars['Int']>;
  lowNotIn?: InputMaybe<Array<Scalars['Int']>>;
  /** medium field predicates */
  medium?: InputMaybe<Scalars['Int']>;
  mediumGT?: InputMaybe<Scalars['Int']>;
  mediumGTE?: InputMaybe<Scalars['Int']>;
  mediumIn?: InputMaybe<Array<Scalars['Int']>>;
  mediumLT?: InputMaybe<Scalars['Int']>;
  mediumLTE?: InputMaybe<Scalars['Int']>;
  mediumNEQ?: InputMaybe<Scalars['Int']>;
  mediumNotIn?: InputMaybe<Array<Scalars['Int']>>;
  not?: InputMaybe<VulnSummaryWhereInput>;
  or?: InputMaybe<Array<VulnSummaryWhereInput>>;
  /** timestamp field predicates */
  timestamp?: InputMaybe<Scalars['Time']>;
  timestampGT?: InputMaybe<Scalars['Time']>;
  timestampGTE?: InputMaybe<Scalars['Time']>;
  timestampIn?: InputMaybe<Array<Scalars['Time']>>;
  timestampLT?: InputMaybe<Scalars['Time']>;
  timestampLTE?: InputMaybe<Scalars['Time']>;
  timestampNEQ?: InputMaybe<Scalars['Time']>;
  timestampNotIn?: InputMaybe<Array<Scalars['Time']>>;
  /** total field predicates */
  total?: InputMaybe<Scalars['Int']>;
  totalGT?: InputMaybe<Scalars['Int']>;
  totalGTE?: InputMaybe<Scalars['Int']>;
  totalIn?: InputMaybe<Array<Scalars['Int']>>;
  totalLT?: InputMaybe<Scalars['Int']>;
  totalLTE?: InputMaybe<Scalars['Int']>;
  totalNEQ?: InputMaybe<Scalars['Int']>;
  totalNotIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type BySubjectDigestQueryVariables = Exact<{
  digest: Scalars['String'];
}>;

export type BySubjectDigestQuery = {
  __typename?: 'Query';
  subjects: {
    __typename?: 'SubjectConnection';
    edges?: Array<{
      __typename?: 'SubjectEdge';
      node?: {
        __typename?: 'Subject';
        statement?: {
          __typename?: 'Statement';
          dsse?: Array<{
            __typename?: 'Dsse';
            gitoidSha256: string;
            statement?: {
              __typename?: 'Statement';
              subjects: {
                __typename?: 'SubjectConnection';
                totalCount: number;
                pageInfo: { __typename?: 'PageInfo'; endCursor?: any | null };
                edges?: Array<{
                  __typename?: 'SubjectEdge';
                  node?: { __typename?: 'Subject'; name: string; subjectDigests?: Array<{ __typename?: 'SubjectDigest'; value: string }> | null } | null;
                } | null> | null;
              };
              attestationCollections?: {
                __typename?: 'AttestationCollection';
                name: string;
                attestations?: Array<{ __typename?: 'Attestation'; type: string }> | null;
              } | null;
            } | null;
          }> | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export const BySubjectDigestDocument = gql`
  query BySubjectDigest($digest: String!) {
    subjects(where: { hasSubjectDigestsWith: { value: $digest } }) {
      edges {
        node {
          statement {
            dsse {
              gitoidSha256
              statement {
                subjects(where: { not: { nameHasPrefix: "https://witness.dev/attestations/product/v0.1" } }) {
                  totalCount
                  pageInfo {
                    endCursor
                  }
                  edges {
                    node {
                      name
                      subjectDigests {
                        value
                      }
                    }
                  }
                }
                attestationCollections {
                  name
                  attestations {
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useBySubjectDigestQuery__
 *
 * To run a query within a React component, call `useBySubjectDigestQuery` and pass it any options that fit your needs.
 * When your component renders, `useBySubjectDigestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBySubjectDigestQuery({
 *   variables: {
 *      digest: // value for 'digest'
 *   },
 * });
 */
export function useBySubjectDigestQuery(
  baseOptions: Apollo.QueryHookOptions<BySubjectDigestQuery, BySubjectDigestQueryVariables> &
    ({ variables: BySubjectDigestQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BySubjectDigestQuery, BySubjectDigestQueryVariables>(BySubjectDigestDocument, options);
}
export function useBySubjectDigestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BySubjectDigestQuery, BySubjectDigestQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BySubjectDigestQuery, BySubjectDigestQueryVariables>(BySubjectDigestDocument, options);
}
export function useBySubjectDigestSuspenseQuery(baseOptions?: Apollo.QueryHookOptions<BySubjectDigestQuery, BySubjectDigestQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BySubjectDigestQuery, BySubjectDigestQueryVariables>(BySubjectDigestDocument, options);
}
export type BySubjectDigestQueryHookResult = ReturnType<typeof useBySubjectDigestQuery>;
export type BySubjectDigestLazyQueryHookResult = ReturnType<typeof useBySubjectDigestLazyQuery>;
export type BySubjectDigestSuspenseQueryHookResult = ReturnType<typeof useBySubjectDigestSuspenseQuery>;
export type BySubjectDigestQueryResult = Apollo.QueryResult<BySubjectDigestQuery, BySubjectDigestQueryVariables>;
