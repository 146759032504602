export const ConfigConstants = {
  hydra: {
    url: '/hydra',
    client_id: 'judge-web',
    client_scopes: 'openid offline',
    root_url: 'https://judge.testifysec.localhost',
  },
  registrar_svc: '/registrar',
  auth_svc: '/auth',
  archivista_svc: '/archivista',
  gateway_svc: '/gateway',
  judgeapi_svc: '/judge-api',
};
